@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto:300,400,500,700,900);
html,
body,
#root,
#root > * {
  height: 1px;
  min-height: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background-color: rgba(231, 239, 242, 0.6);
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  margin: 0; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle; }

a {
  cursor: pointer;
  outline: none;
  text-decoration: none; }

::-webkit-input-placeholder {
  color: #585858; }

::-moz-placeholder {
  color: #585858; }

:-ms-input-placeholder {
  color: #585858; }

:-moz-placeholder {
  color: #585858; }

video[poster] {
  width: 100%;
  height: 100%;
  background-size: 100% 100%; }

input[type="text"],
input[type="email"],
input[type="password"] {
  background-color: transparent;
  padding: 10px 5px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 15px;
  font-family: "Roboto", sans-serif; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: none; }

input[type="submit"] {
  cursor: pointer; }
  input[type="submit"]:focus {
    outline: none; }

textarea, input, button {
  font-family: inherit;
  font-size: inherit; }

mark {
  background-color: transparent;
  padding: 0; }

table {
  width: 100%;
  padding: 70px 0px; }
  table tr {
    height: 27px; }
  table td {
    width: 50%; }
    table td.text-left {
      text-align: left; }
    table td.text-right {
      text-align: right; }
    table td.bold-17 {
      font-weight: bold;
      font-size: 17px; }
    table td.bold-15 {
      font-weight: bold;
      font-size: 15px; }
    table td.border-below {
      border-bottom: 2px solid black;
      border-top-width: 0px; }
    table td.border-above {
      border-top: 2px solid black;
      border-bottom-width: 0px; }
    table td .abbreviation-expanded-text {
      font-size: 16px;
      font-weight: 400; }

.flx {
  display: flex; }
  .flx.full {
    width: 100%; }
  .flx.h-center {
    justify-content: center; }
  .flx.v-center {
    align-items: center; }
  .flx.hv-center {
    justify-content: center;
    align-items: center; }

.flx-col {
  display: flex;
  flex-direction: column; }
  .flx-col.full {
    height: 100%; }
  .flx-col.v-center {
    justify-content: center; }
  .flx-col.h-center {
    align-items: center; }
  .flx-col.hv-center {
    justify-content: center;
    align-items: center; }

.clearfix::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

.container {
  max-width: 1124px;
  margin: 0 auto; }

button {
  outline: none;
  background: #F26C6C;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  color: #F5F7F8;
  font-size: 15px;
  border-radius: 16px;
  height: 32px;
  border: 0px;
  padding: 0;
  box-shadow: none;
  cursor: pointer; }
  button.disabled {
    pointer-events: none;
    background: #CCCCCC; }
  button:focus {
    outline: none; }
  button:hover {
    background-color: #3A4276; }

.btn {
  outline: none !important;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  background-color: transparent;
  color: #666666;
  min-width: 83px;
  min-height: 32px;
  padding: 0;
  border-radius: 16px;
  border: 1px solid #999999;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: inline-block;
  max-width: 90%;
  font-weight: bold;
  margin: auto 11px;
  cursor: pointer; }
  .btn:hover {
    border-color: #F26C6C; }

.btn:focus {
  outline: none;
  box-shadow: none; }

.btn:hover:before,
.btn:focus:before,
.btn:active:before {
  color: #fff; }

.btn-signin {
  line-height: 21px;
  color: #00aeea; }
  .btn-signin:hover {
    color: #fff; }

.btn-transparent,
.btn-transparent-disabled {
  padding: 12px 18px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background-color: transparent;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s; }

.btn-transparent {
  border: 2px solid #00aeea;
  color: #00aeea; }

.btn-transparent-disabled {
  border: 2px solid #a7aeb1;
  color: #a7aeb1; }

.btn-transparent:hover,
.btn-transparent:focus,
.btn-transparent:active {
  color: #00aeea;
  background-color: #fff; }

.btn-gray {
  background-color: #f1f5f7;
  font-weight: 500;
  color: #00aeea;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.btn-gray:hover {
  background-color: #fb6d57;
  color: #fff; }

.btn-notification {
  background-color: #FB6D57;
  height: 50px;
  width: 50px; }

*::-webkit-scrollbar {
  width: 5px;
  visibility: hidden; }

*::-webkit-scrollbar-track {
  background-color: #D8DCDE;
  border-left: 1.5px solid white;
  border-right: 1.5px solid white;
  visibility: hidden; }

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aeaeae;
  visibility: hidden; }

*:hover::-webkit-scrollbar, *:hover::-webkit-scrollbar-thumb {
  visibility: visible; }

*:hover::-webkit-scrollbar-track {
  visibility: hidden; }

*::-webkit-scrollbar {
  height: 5px;
  visibility: hidden; }

*::-webkit-scrollbar-track {
  background-color: #D8DCDE;
  border-top: 1.5px solid white;
  border-bottom: 1.5px solid white;
  visibility: hidden; }

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aeaeae;
  visibility: hidden; }

*:hover::-webkit-scrollbar, *:hover::-webkit-scrollbar-thumb {
  visibility: visible; }

*:hover::-webkit-scrollbar-track {
  visibility: hidden; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #888; }

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #888; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #888; }

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: #888; }

input::placeholder, textarea::placeholder {
  color: #888; }

.mapboxgl-control-container .mapboxgl-ctrl-bottom-left {
  left: 50%; }

iframe {
  width: 100%;
  height: 100vh; }

.toggle-field-wrapper {
  width: 100%;
  height: 35px;
  padding: 10px 0px; }
  .toggle-field-wrapper .toggle-container {
    display: flex;
    width: 100%;
    padding-left: 12px;
    padding-right: 8px;
    flex-direction: row;
    justify-content: flex-start; }
  .toggle-field-wrapper .toggle-box {
    position: relative;
    display: inline-block;
    width: 29px;
    height: 19px;
    margin-right: 25px; }
    .toggle-field-wrapper .toggle-box input {
      opacity: 0; }
      .toggle-field-wrapper .toggle-box input:checked + .toggle:before {
        -webkit-transform: translateX(10px);
                transform: translateX(10px); }
      .toggle-field-wrapper .toggle-box inputinput:checked + .toggle:after {
        position: absolute;
        content: "";
        left: 14px;
        top: 50%;
        width: 18px;
        height: 17px;
        margin-top: -8px;
        background: transparent 0 0 no-repeat; }
    .toggle-field-wrapper .toggle-box .toggle {
      background: #dee2e4;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      border-radius: 34px; }
    .toggle-field-wrapper .toggle-box .toggle:before {
      position: absolute;
      content: "";
      height: 15px;
      width: 15px;
      left: 2px;
      top: 2px;
      background-color: white;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      border-radius: 50%; }
  .toggle-field-wrapper .toggle-label {
    float: left;
    font-size: 15px;
    line-height: 20px; }

.tooltip-helper {
  position: absolute;
  height: 100%;
  width: 100%; }

.component--dropdown {
  max-width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center; }
  .component--dropdown .dropdown-content {
    background-color: #59618D;
    border-radius: 10px;
    box-sizing: border-box;
    opacity: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    pointer-events: none;
    position: absolute;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    -webkit-transition-property: height, opacity, overflow;
    transition-property: height, opacity, overflow;
    z-index: 200;
    width: 240px;
    display: flex;
    top: 45px; }
    .component--dropdown .dropdown-content.empty {
      padding: 0; }
    .component--dropdown .dropdown-content .button {
      display: flex;
      align-items: center;
      font-family: inherit;
      font-size: 13px;
      line-height: 20px;
      height: 27px;
      border-top: 0.5px solid #3A4377;
      color: #EBECF1; }
      .component--dropdown .dropdown-content .button span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 10px; }
      .component--dropdown .dropdown-content .button:hover {
        text-decoration: none; }
    .component--dropdown .dropdown-content .dropdown-content-inner {
      width: 100%;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      max-height: 515px;
      overflow-y: auto; }
    .component--dropdown .dropdown-content ul {
      overflow-y: auto;
      overflow-x: hidden; }
    .component--dropdown .dropdown-content li {
      padding-left: 15px;
      padding-right: 15px;
      margin: 0; }
      .component--dropdown .dropdown-content li:hover {
        background-color: #3A4377;
        cursor: pointer; }
        .component--dropdown .dropdown-content li:hover .button {
          border-color: transparent; }
      .component--dropdown .dropdown-content li:first-child .button {
        border: none; }
    .component--dropdown .dropdown-content li:hover + li .button {
      border-color: transparent; }
    .component--dropdown .dropdown-content ::-webkit-scrollbar-track {
      border: none;
      background-color: #59618D; }
    .component--dropdown .dropdown-content ::-webkit-scrollbar-thumb {
      background: #9db0b8; }
    .component--dropdown .dropdown-content.active {
      opacity: 1;
      pointer-events: auto; }

.component--navbar {
  position: absolute;
  top: 0;
  height: 47px;
  width: 100%;
  background: none;
  background-color: #FFFFFF; }
  .component--navbar a {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .component--navbar .logo {
    width: 155px;
    height: 25.5px;
    background-image: url(/static/media/logo-inline.dd1ad572.svg);
    background-repeat: no-repeat;
    margin-top: 11px;
    margin-left: 15px; }

.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000; }
  .popup .pdf-viewer {
    width: 60vw;
    border: none; }
  .popup .popup-title {
    font-size: 16px;
    color: #333333;
    margin: 0 0 0 5px;
    text-transform: uppercase;
    font-weight: 500; }
  .popup .popup-content {
    font-weight: 400;
    padding: 30px 15px 0px 15px; }
  .popup .popup-inner-wrapper {
    margin-top: 15px; }
  .popup .inp-field {
    background-color: #FCFCFC;
    color: #333333;
    padding: 5px;
    margin-top: 8px;
    border-radius: 7px;
    position: relative; }
    .popup .inp-field label {
      width: 80px;
      color: #888888;
      padding-left: 20px;
      border-right: 1px solid #888888;
      line-height: 35px; }
    .popup .inp-field input {
      display: inline-block;
      width: 80%;
      padding: 8px 15px;
      background-color: transparent;
      font-size: 16px; }

.popup-container-appear {
  opacity: 0; }

.popup-container-appear.popup-container-appear-active {
  opacity: 1;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

.popup-container-enter {
  opacity: 0; }

.popup-container-enter.popup-container-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

.popup-container-leave {
  opacity: 1;
  pointer-events: none; }

.popup-container-leave.popup-container-leave-active {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

.component--toolbar {
  position: relative;
  background-color: #FFFFFF;
  height: 50px;
  display: flex;
  flex-shrink: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
  z-index: 1000; }
  .component--toolbar div:focus {
    outline: 0; }
  .component--toolbar .logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 217px; }
    .component--toolbar .logo-container .logo {
      height: 25.5px;
      width: 195px;
      background-repeat: no-repeat;
      margin-left: 18px;
      cursor: pointer; }
      .component--toolbar .logo-container .logo.pro {
        background-image: url(/static/media/logo-pro-inline.49c54bac.svg); }
      .component--toolbar .logo-container .logo.advanced {
        background-image: url(/static/media/logo-advanced-inline.f28a7f56.svg); }
      .component--toolbar .logo-container .logo.standard {
        background-image: url(/static/media/logo-standard-inline.7a33791d.svg); }
      .component--toolbar .logo-container .logo.none {
        background-image: url(/static/media/logo-beta-inline.089d5bb6.svg); }
  .component--toolbar .toolbar-children-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-end; }
    .component--toolbar .toolbar-children-container .buttons-container {
      position: relative;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: flex-end;
      margin-right: 20px; }
      .component--toolbar .toolbar-children-container .buttons-container .button {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer; }
        .component--toolbar .toolbar-children-container .buttons-container .button:not(:first-child) {
          margin-left: 15px; }
        .component--toolbar .toolbar-children-container .buttons-container .button .icon {
          width: 18px;
          height: 18px;
          margin-bottom: 3px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat; }
          .component--toolbar .toolbar-children-container .buttons-container .button .icon.duplicate-project {
            background-image: url(/static/media/icon-duplicate-project-inactive.c16ebbfb.svg); }
          .component--toolbar .toolbar-children-container .buttons-container .button .icon.new-project {
            background-image: url(/static/media/icon-new-project-inactive.1337b361.svg); }
          .component--toolbar .toolbar-children-container .buttons-container .button .icon.log-in {
            background-image: url(/static/media/icon-log-in-inactive.9862d579.svg); }
          .component--toolbar .toolbar-children-container .buttons-container .button .icon.sign-up {
            background-image: url(/static/media/icon-toolbar-sign-up-inactive.1dd537fa.svg); }
        .component--toolbar .toolbar-children-container .buttons-container .button .text {
          font: 500 8px/8px Roboto;
          color: #3A4276; }
        .component--toolbar .toolbar-children-container .buttons-container .button:hover .duplicate-project, .component--toolbar .toolbar-children-container .buttons-container .button.active .duplicate-project {
          background-image: url(/static/media/icon-duplicate-project-active.42e449f5.svg); }
        .component--toolbar .toolbar-children-container .buttons-container .button:hover .new-project, .component--toolbar .toolbar-children-container .buttons-container .button.active .new-project {
          background-image: url(/static/media/icon-new-project-active.dd3fa1dc.svg); }
        .component--toolbar .toolbar-children-container .buttons-container .button:hover .log-in, .component--toolbar .toolbar-children-container .buttons-container .button.active .log-in {
          background-image: url(/static/media/icon-log-in-active.110c42e2.svg); }
        .component--toolbar .toolbar-children-container .buttons-container .button:hover .sign-up, .component--toolbar .toolbar-children-container .buttons-container .button.active .sign-up {
          background-image: url(/static/media/icon-toolbar-sign-up-active.c601cba8.svg); }
        .component--toolbar .toolbar-children-container .buttons-container .button:hover .text, .component--toolbar .toolbar-children-container .buttons-container .button.active .text {
          color: #4ACBC6; }
    .component--toolbar .toolbar-children-container .component--unit-system-input {
      margin-right: 26px; }

.component--toolbar-settings {
  position: relative; }
  .component--toolbar-settings .dropdown-content {
    line-height: 1;
    position: absolute;
    width: 162px;
    right: 10px;
    top: 40px; }
    .component--toolbar-settings .dropdown-content a {
      font-weight: 400; }
  .component--toolbar-settings .user-settings-menu {
    width: 40px;
    height: 50px;
    right: 0;
    top: 0; }
    .component--toolbar-settings .user-settings-menu .toggle-dropdown {
      height: 100%;
      width: 100%;
      cursor: pointer; }
      .component--toolbar-settings .user-settings-menu .toggle-dropdown .menu-button {
        position: relative;
        margin-right: auto;
        margin-left: auto;
        height: 20px;
        width: 4px;
        top: 15px;
        background-image: url(/static/media/toolbar-settings.764b28f9.svg); }
    .component--toolbar-settings .user-settings-menu:hover .menu-button,
    .component--toolbar-settings .user-settings-menu .menu-button.active {
      background-image: url(/static/media/toolbar-settings-hover.42e7d853.svg); }

.component--branding-watermark {
  width: 100px;
  height: 19px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto; }
  .component--branding-watermark .icon-watermark {
    display: block;
    height: 100%;
    width: 100%;
    background: url(/static/media/branding-watermark.c1dd49bb.svg);
    background-size: contain;
    background-repeat: no-repeat; }

.component--sidebar {
  display: flex;
  flex-direction: column;
  min-width: 195px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #F8F8F8;
  padding: 21px 0 13px; }
  .component--sidebar .sidebar-block {
    margin-bottom: 38px;
    padding: 0px 16px; }
    .component--sidebar .sidebar-block h5 {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 400;
      color: #777777;
      padding-bottom: 3px;
      border-bottom: 1px solid #777777; }
    .component--sidebar .sidebar-block .sidebar-nav {
      margin: 8px 0; }
      .component--sidebar .sidebar-block .sidebar-nav .side-bar-links {
        margin-bottom: 5px;
        color: #777777;
        font-size: 13px;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-transition-property: height;
        transition-property: height;
        height: 28px; }
        .component--sidebar .sidebar-block .sidebar-nav .side-bar-links .icon {
          width: 30px;
          height: 25px;
          margin-right: 7px; }
        .component--sidebar .sidebar-block .sidebar-nav .side-bar-links.hidden {
          visibility: hidden;
          height: 0px;
          margin: 0px; }

.component--pdf-generator .component--map-image {
  position: fixed;
  left: -10000px;
  bottom: -10000px;
  height: 800px;
  width: 566px;
  z-index: -100; }
  .component--pdf-generator .component--map-image.parcel {
    width: 616px; }
  .component--pdf-generator .component--map-image.cover {
    height: 826px; }

.component--pdf-viewer {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 900px;
  max-width: 80vw;
  border: none;
  z-index: 3000; }

.close-cross {
  background-image: url(/static/media/white-cross.fdc9049a.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 100px;
  right: 50px;
  width: 27.4px;
  height: 29.07px;
  cursor: pointer; }

.component--busy-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; }
  .component--busy-spinner .spinner {
    width: 40px;
    height: 40px;
    background-image: url(/static/media/busy-spinner-black.bbb7879c.svg);
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear; }
  .component--busy-spinner.paying-spinner .spinner {
    background-image: url(/static/media/busy-spinner-white.c29c69ee.svg); }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.component--busy-popup {
  width: 300px; }
  .component--busy-popup .text-box {
    font-family: Roboto Condensed;
    font-weight: 700;
    font-size: 20px;
    color: white;
    text-align: center; }
  .component--busy-popup .component--busy-spinner {
    height: 120px; }
    .component--busy-popup .component--busy-spinner .spinner {
      width: 60px;
      height: 60px;
      background-image: url(/static/media/busy-spinner-white.c29c69ee.svg); }

.component--activity-feedback .spinner-wrapper {
  height: 140px; }

.component--activity-feedback .secondary-text {
  text-align: center;
  font-size: 16px;
  color: #777777; }

td.component--cell {
  text-align: right; }
  td.component--cell.bold {
    font-weight: bold; }
  td.component--cell .blurry-text {
    color: transparent;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none; }
  td.component--cell.justify-left {
    text-align: left; }

.component--parcel-information-table {
  width: 100%; }
  .component--parcel-information-table table {
    background-color: #FFFFFF;
    color: #939393; }
    .component--parcel-information-table table th {
      height: 40px;
      padding-top: 15px;
      font: Bold 14px/18px Roboto;
      letter-spacing: 1.19px;
      text-align: center;
      color: #3A4276;
      text-transform: uppercase;
      border-bottom: 2px solid #3A4276; }
    .component--parcel-information-table table tr {
      line-height: 15px; }
      .component--parcel-information-table table tr.center td {
        text-align: center; }
      .component--parcel-information-table table tr:nth-child(even) {
        background-color: #FBFBFB; }
      .component--parcel-information-table table tr td {
        border: 1px solid #D1D5DD;
        border-top: none;
        vertical-align: top; }
        .component--parcel-information-table table tr td:first-child {
          padding: 10px 3px 10px 11px;
          font-weight: 400;
          width: 150px; }
        .component--parcel-information-table table tr td:last-child {
          padding: 10px 18px 10px 3px;
          font: 700 14px/15px Roboto;
          color: #3A4276; }
        .component--parcel-information-table table tr td.yellow {
          color: #F9B923;
          font-weight: 700; }
        .component--parcel-information-table table tr td.capitalize {
          text-transform: capitalize; }
        .component--parcel-information-table table tr td.uppercase {
          text-transform: uppercase; }
      .component--parcel-information-table table tr .array-item {
        margin-bottom: 6px;
        padding-left: 25px;
        display: block; }
        .component--parcel-information-table table tr .array-item:last-child {
          margin-bottom: 0; }

.component--federated-authentication-providers .component--button-facebook {
  background-color: #3F569A; }

.component--federated-authentication-providers .component--button-google {
  background-color: #DB4437;
  margin-top: 8px; }

.component--federated-authentication-providers {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px; }
  .component--federated-authentication-providers .top-separator {
    position: relative;
    display: flex;
    justify-content: center;
    height: 52px;
    width: 360px;
    background-color: #EEEEEE;
    border-top: solid #FFFFFF 23px;
    border-bottom: solid #FFFFFF 23px; }
  .component--federated-authentication-providers .circle {
    position: absolute;
    bottom: -23px;
    background-color: #FFFFFF;
    color: #E0E0E0;
    height: 52px;
    width: 52px;
    font: Bold 20px/40px Roboto;
    border-radius: 26px;
    border: solid #EEEEEE 6px;
    text-align: center; }

.component--user-loading-feedback {
  height: 100%;
  weight: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; }
  .component--user-loading-feedback .text-box {
    font-family: "Roboto Condensed";
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    text-align: center; }
  .component--user-loading-feedback .component--busy-spinner {
    height: 120px; }
    .component--user-loading-feedback .component--busy-spinner .spinner {
      width: 60px;
      height: 60px; }

.component--unit-system-input {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  position: relative; }
  .component--unit-system-input .select-container {
    display: flex; }
  .component--unit-system-input label {
    pointer-events: none;
    margin: 0 23px 0 0;
    padding-left: 34px;
    position: relative; }
  .component--unit-system-input .custom-radio {
    position: absolute;
    top: 2px;
    left: 0;
    height: 17px;
    width: 17px;
    background: #FDFDFD 0% 0% no-repeat padding-box;
    border-radius: 50%;
    border: 1px solid #CCCCCC; }
    .component--unit-system-input .custom-radio.selected:after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      height: 13px;
      width: 13px;
      border-radius: 50%;
      background: #3A4276 0% 0% no-repeat padding-box; }
  .component--unit-system-input input[name="unitSystem"] {
    position: absolute;
    top: 2px;
    left: 0;
    height: 17px;
    width: 17px;
    pointer-events: all;
    cursor: pointer;
    opacity: 0; }

.component--redirect-button {
  color: #3A4276;
  font-size: 13px;
  height: 32px;
  min-height: 32px;
  padding: 6px 15px;
  border: 1px solid #3A4276;
  margin: auto 10px;
  flex-shrink: 0; }
  .component--redirect-button:hover {
    border: 1px solid #3A4276;
    background-color: #3A4276;
    color: white; }
  .component--redirect-button.btn.basic {
    border: 1px solid #F26C6C;
    background-color: #F26C6C; }
    .component--redirect-button.btn.basic:hover {
      border: 1px solid #3A4276;
      background-color: #3A4276; }

.component--mapbox-geocoder .geocoder-input {
  position: relative;
  display: flex;
  align-items: center;
  /* Suggestions */ }
  .component--mapbox-geocoder .geocoder-input input {
    color: #777777;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .component--mapbox-geocoder .geocoder-input ul {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 0 0 3px 3px;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    top: 100%;
    overflow: hidden;
    font-size: 12px;
    z-index: 100; }
  .component--mapbox-geocoder .geocoder-input li {
    margin-bottom: 0;
    padding: 0px 0px; }
  .component--mapbox-geocoder .geocoder-input .suggestion {
    clear: both;
    cursor: default;
    display: block;
    padding: 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #404040; }
    .component--mapbox-geocoder .geocoder-input .suggestion:hover {
      color: #202020;
      background-color: #f3f3f3;
      text-decoration: none;
      cursor: pointer; }
  .component--mapbox-geocoder .geocoder-input ul > li:last-child > .suggestion {
    border-bottom: none; }
  .component--mapbox-geocoder .geocoder-input ul > li.active > .suggestion {
    color: #202020;
    background-color: #e3e3e3;
    text-decoration: none;
    cursor: pointer; }
  .component--mapbox-geocoder .geocoder-input .geocoder-icon {
    position: absolute;
    background-repeat: no-repeat;
    cursor: pointer; }
  .component--mapbox-geocoder .geocoder-input .geocoder-icon-search {
    top: 7px;
    right: 8px;
    width: 15px;
    height: 17px; }
    .component--mapbox-geocoder .geocoder-input .geocoder-icon-search.active {
      background-image: url(/static/media/icon-search-red.997c27b1.svg);
      pointer-events: all; }
    .component--mapbox-geocoder .geocoder-input .geocoder-icon-search.inactive {
      cursor: inherit;
      background-image: url(/static/media/icon-search-gray.b1d00c02.svg);
      pointer-events: none; }
  .component--mapbox-geocoder .geocoder-input .geocoder-icon-close {
    top: 10px;
    right: 10px;
    width: 10px;
    height: 11px;
    background-image: url(/static/media/icon-cross-out.579a9698.svg);
    background-color: white; }

.component--return-on-cost {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 20px;
  right: 60px;
  width: 120px;
  padding: 8px 8px 15px;
  border-radius: 10px;
  background-color: #3A4276;
  color: #FFFFFF;
  text-align: center;
  line-height: 15px; }
  .component--return-on-cost .header {
    font: Bold 16px/18px Roboto;
    letter-spacing: 0.33px;
    text-transform: uppercase; }
  .component--return-on-cost .separator {
    margin-top: 3px;
    width: 35px;
    height: 3px;
    background-color: #F66C6C;
    border-radius: 1.5px; }
  .component--return-on-cost .value {
    margin-top: 15px;
    font: 700 30px/30px Roboto;
    letter-spacing: -0.5px; }
  .component--return-on-cost .secondary {
    position: absolute;
    bottom: -27px;
    width: 120px;
    height: 35px;
    background-color: #FFFFFF;
    color: #3A4276;
    padding: 11px 0 0;
    border-radius: 0 0 10px 10px; }
    .component--return-on-cost .secondary .label {
      font: 300 14px/14px Roboto; }
    .component--return-on-cost .secondary .value {
      font: 900 14px/14px Roboto; }

.component-react-tooltip {
  opacity: 1 !important;
  margin-left: 63px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  padding: 8px 10px !important; }
  .component-react-tooltip::before {
    background-image: url(/static/media/tooltip-triangle.919e315e.svg);
    background-repeat: no-repeat;
    background-size: contain;
    border: none !important;
    width: 85px !important;
    height: 85px !important;
    margin: 0 !important;
    top: 161px !important;
    left: -60px !important; }
  .component-react-tooltip::after {
    top: 161px !important;
    left: 0px !important;
    border: none !important;
    width: 20px !important;
    height: 85px !important;
    background-color: #fff; }
  .component-react-tooltip.offset-triangle::before {
    width: 85px;
    height: 85px;
    top: 17px !important; }
  .component-react-tooltip.offset-triangle::after {
    top: 20px !important;
    width: 20px !important;
    height: 85px !important; }

.component--tooltip {
  width: 325px;
  height: 377px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 14px 2px 0; }
  .component--tooltip a {
    color: #3A4276; }
  .component--tooltip .image {
    width: 230px;
    height: 152px;
    margin-bottom: 26px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
    .component--tooltip .image.tooltip-smart-search {
      background-image: url(/static/media/tooltip-smart-search.0099cacc.png); }
    .component--tooltip .image.tooltip-back-of-envelope {
      background-image: url(/static/media/tooltip-boe-panel.5c339ee6.png); }
    .component--tooltip .image.tooltip-dashboard {
      background-image: url(/static/media/tooltip-dashboard.19555fa5.png); }
    .component--tooltip .image.tooltip-information-panel {
      background-image: url(/static/media/tooltip-information-panel.c1a24a6c.png); }
    .component--tooltip .image.tooltip-pdf {
      background-image: url(/static/media/tooltip-pdf-panel.a7858470.png); }
  .component--tooltip .header {
    font: 16px/18px Roboto;
    letter-spacing: 0.32px;
    color: #3A4276;
    text-transform: uppercase; }
    .component--tooltip .header.thin {
      font-weight: 100; }
    .component--tooltip .header.bold {
      font-weight: bold; }
  .component--tooltip .content {
    text-align: center;
    font: 100 14px/18px Roboto;
    letter-spacing: 0.24px;
    color: #999999;
    margin-top: 14px;
    white-space: pre-wrap; }
  .component--tooltip .btn {
    width: 124px;
    height: 24px;
    min-height: 24px;
    color: #fff;
    padding: 0;
    background-color: #3A4276;
    border-radius: 5px;
    margin: 26px 0 0;
    font: normal 13px/100% Roboto;
    letter-spacing: 0.26px; }
  .component--tooltip .not-now {
    text-decoration: underline;
    font: normal 10px/10px Roboto;
    letter-spacing: 0.2px;
    color: #999999;
    text-transform: capitalize;
    margin: 8px 0 0;
    cursor: pointer; }

.component--log-in {
  position: relative;
  background-color: #FFFFFF;
  padding: 20px 12px;
  border-radius: 3px; }
  .component--log-in form {
    display: flex;
    flex-direction: column; }
  .component--log-in .header {
    height: 35px;
    border-bottom: 1px solid #E0E0E0;
    text-align: center;
    font: 700 20px/25px Roboto;
    color: #263238;
    text-transform: uppercase; }
  .component--log-in .input-field {
    margin-top: 16px; }
  .component--log-in .error-container p {
    font: 300 10px/20px Roboto;
    font-style: italic;
    color: #F26C6C;
    margin-top: 7px;
    margin-bottom: -15px;
    white-space: pre-line; }
  .component--log-in .tos-agreement {
    display: flex;
    align-items: center;
    margin-top: 20px; }
  .component--log-in .text-box {
    font: 400 12px/14px Roboto;
    color: #777777; }
  .component--log-in a {
    font: 400 12px/14px Roboto;
    color: #777777;
    text-decoration: underline; }
    .component--log-in a.forgot-password {
      margin-left: auto; }
  .component--log-in input {
    width: 100%;
    background-color: #F1F5F7;
    border: 1px solid #EEEEEE;
    border-radius: 0; }
    .component--log-in input.tos {
      width: 24px; }
  .component--log-in button {
    width: 100%;
    height: 46px;
    background-color: #3A4276;
    border-radius: 3PX;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px;
    box-shadow: 0px 4px 10px #00000040; }
  .component--log-in .submit:hover {
    background-color: #2C3258; }

.component--log-in-popup {
  width: 360px;
  background-color: #FFFFFF;
  border-radius: 3px; }
  .component--log-in-popup::before {
    position: absolute;
    top: -36px;
    right: 155px;
    content: "";
    width: 50px;
    height: 50px;
    background-image: url(/static/media/tooltip-triangle.919e315e.svg);
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .component--log-in-popup::after {
    position: absolute;
    top: 0px;
    content: "";
    width: 50px;
    height: 10px;
    background-color: #fff;
    border-radius: 3px; }
  .component--log-in-popup.on-home-page {
    position: absolute;
    top: 100px;
    right: 30px; }
  .component--log-in-popup.on-explorer-page {
    position: absolute;
    top: 65px;
    right: 20px; }
    .component--log-in-popup.on-explorer-page::before {
      top: -28px;
      right: 68px; }
  .component--log-in-popup.on-subscribe-page {
    width: 483px; }
    .component--log-in-popup.on-subscribe-page::before, .component--log-in-popup.on-subscribe-page::after {
      content: none; }
    .component--log-in-popup.on-subscribe-page .component--log-in {
      position: relative;
      padding: 0px 12px 45px; }
      .component--log-in-popup.on-subscribe-page .component--log-in form {
        margin-top: 14px; }
      .component--log-in-popup.on-subscribe-page .component--log-in .header {
        position: relative;
        right: 12px;
        width: 483px;
        height: 66px;
        color: #3A4276;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #3A4276; }
      .component--log-in-popup.on-subscribe-page .component--log-in .input-field {
        margin-top: 21px; }
      .component--log-in-popup.on-subscribe-page .component--log-in input {
        height: 61px;
        padding: 0 17px; }
      .component--log-in-popup.on-subscribe-page .component--log-in input[type="checkbox"] {
        height: 25px; }
      .component--log-in-popup.on-subscribe-page .component--log-in .component--federated-authentication-providers {
        margin-top: 44px;
        flex-direction: column; }
        .component--log-in-popup.on-subscribe-page .component--log-in .component--federated-authentication-providers .top-separator {
          width: 483px; }
        .component--log-in-popup.on-subscribe-page .component--log-in .component--federated-authentication-providers .buttons-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-top: 37px; }
        .component--log-in-popup.on-subscribe-page .component--log-in .component--federated-authentication-providers .component--button-facebook {
          background-color: #3F569A;
          margin: 0; }
        .component--log-in-popup.on-subscribe-page .component--log-in .component--federated-authentication-providers .component--button-google {
          background-color: #DB4437;
          margin: 18px 0 0; }
      .component--log-in-popup.on-subscribe-page .component--log-in button {
        height: 60px; }

.component--geocoder-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .component--geocoder-search .logo {
    width: 410px;
    height: 169px;
    background-image: url(/static/media/logo-inline-detail.f631886b.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 35px; }
  .component--geocoder-search .geocoder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px; }
  .component--geocoder-search .component--mapbox-geocoder {
    border: 1px solid #A8A8A8;
    border-radius: 29px;
    margin-bottom: 50px;
    box-shadow: 0px 3px 6px #0000000D; }
    .component--geocoder-search .component--mapbox-geocoder .geocoder-icon-search {
      opacity: 0; }
    .component--geocoder-search .component--mapbox-geocoder .geocoder-icon-close {
      background-color: transparent;
      top: 21px;
      right: 18px;
      width: 13px;
      height: 14px; }
    .component--geocoder-search .component--mapbox-geocoder input {
      width: 700px;
      height: 58px;
      padding: 17px 46px;
      color: #999999;
      font: 400 16px/16px Roboto;
      letter-spacing: 0.4px; }
  .component--geocoder-search .error-container p {
    font: 300 13px/20px Roboto;
    font-style: italic;
    color: #F26C6C;
    margin-top: -40px;
    margin-bottom: 20px; }
  .component--geocoder-search .component--redirect-button {
    color: #FFFFFF;
    background-color: #3A4276;
    height: 54px;
    width: 242px;
    border-radius: 27px;
    font: Bold 21px/34px Roboto;
    letter-spacing: 0.21px;
    padding: 0;
    margin: 0;
    border: none; }
    .component--geocoder-search .component--redirect-button:hover {
      background-color: #2C3258; }

.component--share-project-popup {
  min-width: 400px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #818181;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px; }
  .component--share-project-popup .top {
    width: 100%;
    background-color: #3A4276;
    padding: 15px 12px 10px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px 6px 0 0; }
    .component--share-project-popup .top .header {
      font: 900 16px/21px Roboto; }
    .component--share-project-popup .top .top-separator {
      width: 52px;
      height: 1px;
      background-color: #FFFFFF;
      margin: 13px 0; }
  .component--share-project-popup .bottom {
    width: 100%;
    padding: 20px 16px; }
    .component--share-project-popup .bottom .url-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      background-color: #F5F7F8;
      border-radius: 6px;
      font: 400 11px/21px Roboto;
      color: #3A4276;
      padding: 0 10px; }
    .component--share-project-popup .bottom button {
      margin-top: 13px;
      width: 130px;
      height: 25px;
      font: 700 13px/21px Roboto;
      color: #FFFFFF;
      background-color: #4ACBC6;
      border-radius: 6px; }
  .component--share-project-popup.explorer {
    position: absolute;
    top: 65px;
    right: 20px; }
    .component--share-project-popup.explorer::before {
      position: absolute;
      top: -34px;
      right: 124px;
      content: "";
      width: 30px;
      height: 50px;
      background-image: url(/static/media/tooltip-triangle-blue.e93c1576.svg);
      background-size: contain;
      background-repeat: no-repeat;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
    .component--share-project-popup.explorer::after {
      position: absolute;
      top: 0px;
      right: 105px;
      content: "";
      width: 50px;
      height: 10px;
      background-color: #3A4276;
      border-radius: 3px; }
    .component--share-project-popup.explorer.non-authenticated::before {
      right: 154px; }
    .component--share-project-popup.explorer.non-authenticated::after {
      right: 135px; }

.component--share-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; }
  .component--share-project .icon {
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(/static/media/icon-share-project-inactive.86924843.svg); }
  .component--share-project .text {
    font: 500 8px/8px Roboto;
    color: #3A4276; }
  .component--share-project:hover .icon, .component--share-project.active .icon {
    background-image: url(/static/media/icon-share-project-active.de209b9a.svg); }
  .component--share-project:hover .text, .component--share-project.active .text {
    color: #4ACBC6; }

.component--delete-development-popup {
  width: 360px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #FFFFFF;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px; }
  .component--delete-development-popup .top {
    background-color: #3A4276;
    padding: 20px 18px 12px;
    color: #FFFFFF;
    border-radius: 6px 6px 0 0; }
    .component--delete-development-popup .top .separator {
      margin-top: 9px;
      height: 1px;
      background-color: #FFFFFF80; }
    .component--delete-development-popup .top .italic {
      margin-top: 9px;
      font: Italic 12px/21px Roboto; }
    .component--delete-development-popup .top .bold {
      font-weight: 700; }
  .component--delete-development-popup .bottom {
    padding: 20px 0 25px; }
  .component--delete-development-popup .buttons-container {
    display: flex;
    width: 100%;
    justify-content: center; }
  .component--delete-development-popup button {
    width: 131px;
    height: 25px;
    min-height: 25px;
    box-shadow: 0px 4px 10px #00000040;
    border-radius: 6px;
    border: none;
    color: #FFFFFF;
    font: 700 13px/13px Roboto;
    padding: 0; }
    .component--delete-development-popup button.cancel {
      background-color: #3A4276; }
    .component--delete-development-popup button.confirm {
      background-color: #F26C6C;
      margin-left: 35px; }
    .component--delete-development-popup button:hover {
      border: none; }

.component--delete-development {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; }
  .component--delete-development .icon {
    width: 18px;
    height: 18px;
    margin-bottom: 3px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(/static/media/icon-trash-can-blue.dd90b700.svg); }
  .component--delete-development .text {
    font: 500 8px/8px Roboto;
    color: #3A4276; }
  .component--delete-development:hover .icon, .component--delete-development.active .icon {
    background-image: url(/static/media/icon-trash-can-green.b3f05828.svg); }
  .component--delete-development:hover .text, .component--delete-development.active .text {
    color: #4ACBC6; }

.component--property-listing-tab-container {
  width: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .component--property-listing-tab-container .tab-container {
    display: flex;
    position: relative;
    justify-content: space-evenly;
    border-bottom: 1px solid #D1D5DD;
    height: 35px;
    margin-bottom: 15px; }
    .component--property-listing-tab-container .tab-container::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 25px;
      border-right: 1px solid #D1D5DD; }
    .component--property-listing-tab-container .tab-container .tab {
      font: Bold 17px/34px Roboto;
      letter-spacing: 0.17px;
      color: #3A4276;
      padding-bottom: 5px;
      position: relative;
      display: flex;
      flex: 1 1 50%;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer; }
      .component--property-listing-tab-container .tab-container .tab.active::after {
        content: "";
        position: absolute;
        bottom: -2px;
        height: 3px;
        width: 110px;
        background: #3A4276 0% 0% no-repeat padding-box; }
      .component--property-listing-tab-container .tab-container .tab.disabled {
        color: #D1D5DD; }
  .component--property-listing-tab-container .content-wrapper {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden; }
    .component--property-listing-tab-container .content-wrapper.hidden {
      display: none; }
    .component--property-listing-tab-container .content-wrapper .component--accordion-section {
      width: 100%; }

.component--property-listing {
  display: flex;
  flex-direction: column;
  align-items: center;
  font: 400 14px/21px Roboto;
  color: #939393;
  text-align: center;
  overflow: auto;
  width: 100%; }
  .component--property-listing .missing-listing-icon {
    width: 332px;
    height: 194px;
    background: url(/static/media/icon-no-listing.6a89d894.svg) no-repeat;
    margin-bottom: 68px; }
  .component--property-listing .learn-more {
    margin-top: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 178px;
    height: 35px;
    background: #3A4276 0% 0% no-repeat padding-box;
    font: normal 15px/21px Roboto;
    color: #FFFFFF;
    border-radius: 7px; }
  .component--property-listing .disclaimer {
    margin-bottom: 10px; }
  .component--property-listing .table-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: auto;
    padding-right: 1px; }
    .component--property-listing .table-container table {
      table-layout: fixed;
      width: 100%;
      margin-top: 10px; }
      .component--property-listing .table-container table:nth-child(1) {
        margin-top: 0; }
      .component--property-listing .table-container table th {
        height: 40px;
        padding-top: 15px;
        letter-spacing: 1.19px;
        color: #3A4276;
        text-transform: uppercase;
        border-bottom: 2px solid #3A4276; }
      .component--property-listing .table-container table tr {
        height: 34px; }
        .component--property-listing .table-container table tr:nth-child(even) {
          background-color: #F5F5F533; }
        .component--property-listing .table-container table tr td {
          border: 1px solid #D1D5DD;
          color: #3A4276;
          padding: 5px 10px; }
          .component--property-listing .table-container table tr td.text-left {
            color: #939393; }

.component--accordion-section {
  margin-bottom: 18px; }
  .component--accordion-section .section-title {
    height: 45px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    font: 700 18px/21px Roboto;
    letter-spacing: 1.53px;
    text-transform: uppercase;
    cursor: pointer;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #3A4276;
    border: 1px solid rgba(58, 66, 118, 0.5); }
    .component--accordion-section .section-title .title-wrapper {
      display: flex; }
    .component--accordion-section .section-title .icon {
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: contain; }
    .component--accordion-section .section-title .title-icon {
      margin-right: 11px; }
    .component--accordion-section .section-title .open-close-icon {
      background-position: center;
      background-image: url(/static/media/icon-plus.8574f785.svg); }
    .component--accordion-section .section-title .name-uses {
      background-image: url(/static/media/icon-name-uses-blue.30bf0c22.svg); }
    .component--accordion-section .section-title .demographics {
      background-image: url(/static/media/icon-demographics-blue.524bc3e7.svg); }
    .component--accordion-section .section-title .property-info {
      background-image: url(/static/media/icon-property-info-blue.1012a71a.svg); }
    .component--accordion-section .section-title .zoning {
      background-image: url(/static/media/icon-zoning-blue.8cf05d95.svg); }
    .component--accordion-section .section-title.open {
      background: #DCF2F2 0% 0% no-repeat padding-box;
      border: none; }
      .component--accordion-section .section-title.open .open-close-icon {
        background-image: url(/static/media/icon-minus.ab85d4c7.svg); }

.component--email-verification-complete-page {
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: #F6F6F6;
  min-height: 100%; }
  .component--email-verification-complete-page .hidden {
    display: none; }
  .component--email-verification-complete-page a {
    font: inherit;
    font-weight: bold;
    color: #3A4276; }
  .component--email-verification-complete-page button {
    background-color: #3A4276;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer; }
  .component--email-verification-complete-page .underlined-link {
    font-weight: 500;
    color: #333333;
    text-decoration: underline;
    cursor: pointer; }
    .component--email-verification-complete-page .underlined-link:hover {
      color: #3A4276;
      text-decoration: underline; }
  .component--email-verification-complete-page .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .component--email-verification-complete-page .content-wrapper {
    position: absolute;
    top: 241px; }
  .component--email-verification-complete-page .header {
    font-size: 25px;
    font-family: "Roboto Condensed";
    font-weight: 700; }
  .component--email-verification-complete-page .container {
    width: 395px;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    padding: 35px 30px 38px;
    box-shadow: 0px 8px 20px #00000017;
    border-radius: 10px; }
  .component--email-verification-complete-page .content-header {
    flex-shrink: 0;
    width: 240.5px;
    height: 39.5px;
    background-image: url(/static/media/logo-inline.dd1ad572.svg);
    background-repeat: no-repeat;
    background-size: contain; }
  .component--email-verification-complete-page .text-box {
    height: auto;
    width: auto;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    line-height: 19px; }
  .component--email-verification-complete-page .form-container + .text-box {
    margin-top: 33px; }
  .component--email-verification-complete-page .form-container .input-section {
    width: auto;
    margin-bottom: 16px; }
    .component--email-verification-complete-page .form-container .input-section .input-label {
      width: auto;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin: 15px 0 5px 10px;
      line-height: 10px; }
    .component--email-verification-complete-page .form-container .input-section .input-field {
      position: relative;
      height: 32px;
      width: 335px;
      background-color: #FDFDFD;
      padding: 4px 13px 4px 4px;
      border: 1px solid #CCCCCC;
      border-radius: 8px; }
      .component--email-verification-complete-page .form-container .input-section .input-field input {
        border: none;
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 15px;
        font-family: inherit;
        font-size: 15px;
        background-color: transparent; }
        .component--email-verification-complete-page .form-container .input-section .input-field input:-webkit-autofill {
          border: none;
          box-shadow: 0 0 0px 1000px white inset; }
        .component--email-verification-complete-page .form-container .input-section .input-field input::-webkit-input-placeholder {
          color: #CCCCCC; }
        .component--email-verification-complete-page .form-container .input-section .input-field input::-moz-placeholder {
          color: #CCCCCC; }
        .component--email-verification-complete-page .form-container .input-section .input-field input:-ms-input-placeholder {
          color: #CCCCCC; }
        .component--email-verification-complete-page .form-container .input-section .input-field input::-ms-input-placeholder {
          color: #CCCCCC; }
        .component--email-verification-complete-page .form-container .input-section .input-field input::placeholder {
          color: #CCCCCC; }
      .component--email-verification-complete-page .form-container .input-section .input-field.required:after {
        content: "*";
        position: absolute;
        top: 5px;
        right: 8px;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: bold;
        color: #F26C6C; }
      .component--email-verification-complete-page .form-container .input-section .input-field.dropdown-arrow {
        background-image: url(/static/media/dropdown-triangle.d4ccc411.svg);
        background-repeat: no-repeat;
        background-position: center right;
        background-origin: content-box;
        background-size: 15px;
        content: "";
        display: block;
        padding-right: 7.5px;
        cursor: pointer; }
        .component--email-verification-complete-page .form-container .input-section .input-field.dropdown-arrow input {
          cursor: pointer; }
        .component--email-verification-complete-page .form-container .input-section .input-field.dropdown-arrow:hover {
          background-image: url(/static/media/dropdown-triangle-hover.4dec3ec1.svg); }
  .component--email-verification-complete-page .form-container .submit {
    height: 35px;
    width: 97px;
    letter-spacing: 0.25px;
    padding: 0;
    pointer-events: all; }
    .component--email-verification-complete-page .form-container .submit.disabled {
      cursor: inherit;
      color: white; }
  .component--email-verification-complete-page .bold {
    font-weight: bold; }
  .component--email-verification-complete-page .error-message {
    display: flex;
    align-items: flex-end;
    height: 31px;
    margin: 25px 0 13px 5px;
    width: 294px;
    text-align: left;
    vertical-align: middle;
    font-family: "Roboto Condensed", "Regular";
    font-size: 13px;
    color: #F26C6C;
    line-height: 16px; }
  .component--email-verification-complete-page .reset-password {
    margin-top: 33px; }
  .component--email-verification-complete-page .tos-agreement {
    height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 36px; }
    .component--email-verification-complete-page .tos-agreement input[type="checkbox"] {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background-color: #F5F7F8;
      border: 1px solid #CCCCCC;
      padding: 6.5px;
      border-radius: 5px;
      width: 0;
      height: 0; }
      .component--email-verification-complete-page .tos-agreement input[type="checkbox"]:checked {
        border: 1px solid #333333;
        background: url(/static/media/checkmark.e3d7ea53.svg);
        background-size: contain;
        background-repeat: no-repeat; }
      .component--email-verification-complete-page .tos-agreement input[type="checkbox"]:focus {
        outline: none; }
    .component--email-verification-complete-page .tos-agreement .text-box {
      margin-left: 14px; }
  @media screen and (max-width: 794px) {
    .component--email-verification-complete-page .flex-wrapper {
      flex-direction: column-reverse; } }

.component--kpi {
  flex: 1 0 225px;
  display: block;
  line-height: 1;
  height: 50px;
  color: #333333;
  float: left; }
  .component--kpi .kpi-item-container .kpi-item {
    height: 37px;
    min-width: 220px;
    border: none;
    border-left: 1px solid #4D7887;
    padding-left: 7px;
    cursor: pointer;
    font-family: inherit;
    font-weight: 400;
    color: inherit;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: transparent;
    padding-top: 2px; }
    .component--kpi .kpi-item-container .kpi-item:focus {
      outline: 0; }
    .component--kpi .kpi-item-container .kpi-item .kpi-title {
      font-size: 12px;
      width: 100%; }
    .component--kpi .kpi-item-container .kpi-item .kpi-values {
      margin: 3px 0 0 0;
      font-size: 18.5px;
      position: absolute;
      bottom: 0;
      display: flex; }
      .component--kpi .kpi-item-container .kpi-item .kpi-values .kpi-triangle {
        margin-left: 12px;
        margin-top: 8px; }
    .component--kpi .kpi-item-container .kpi-item:hover .kpi-values .kpi-triangle, .component--kpi .kpi-item-container .kpi-item .kpi-triangle.active {
      background: url(/static/media/dropdown-triangle-hover.4dec3ec1.svg) no-repeat; }

.component--copy-project-popup {
  position: absolute;
  top: 65px;
  right: 20px;
  width: 360px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #818181;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px; }
  .component--copy-project-popup .top {
    width: 100%;
    background-color: #3A4276;
    padding: 15px 12px 10px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px 6px 0 0; }
    .component--copy-project-popup .top .header {
      font: 900 16px/21px Roboto; }
    .component--copy-project-popup .top .top-separator {
      width: 52px;
      height: 1px;
      background-color: #FFFFFF;
      margin: 13px 0; }
  .component--copy-project-popup form {
    width: 100%;
    padding: 20px 16px; }
    .component--copy-project-popup form .input-container {
      display: flex;
      align-items: center;
      height: 25px;
      background-color: #F5F7F8;
      border-radius: 6px; }
    .component--copy-project-popup form label {
      color: #606060;
      font: 700 12px/21px Roboto;
      margin: 0 0 0 11px; }
    .component--copy-project-popup form input {
      font: 400 11px/21px Roboto;
      color: #ADADAD;
      flex-grow: 1; }
      .component--copy-project-popup form input::-webkit-input-placeholder {
        color: #ADADAD; }
      .component--copy-project-popup form input::-moz-placeholder {
        color: #ADADAD; }
      .component--copy-project-popup form input:-ms-input-placeholder {
        color: #ADADAD; }
      .component--copy-project-popup form input::-ms-input-placeholder {
        color: #ADADAD; }
      .component--copy-project-popup form input::placeholder {
        color: #ADADAD; }
    .component--copy-project-popup form button {
      margin-top: 13px;
      width: 130px;
      height: 25px;
      font: 700 13px/21px Roboto;
      color: #FFFFFF;
      background-color: #4ACBC6;
      border-radius: 6px; }
  .component--copy-project-popup::before {
    position: absolute;
    top: -34px;
    right: 83px;
    content: "";
    width: 30px;
    height: 50px;
    background-image: url(/static/media/tooltip-triangle-blue.e93c1576.svg);
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .component--copy-project-popup::after {
    position: absolute;
    top: 0px;
    right: 65px;
    content: "";
    width: 50px;
    height: 10px;
    background-color: #3A4276;
    border-radius: 3px; }

.component--redirect-warning-popup {
  position: absolute;
  top: 65px;
  right: 20px;
  width: 360px;
  height: 285px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #818181;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px; }
  .component--redirect-warning-popup .top {
    background-color: #3A4276;
    padding: 15px 12px 28px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px 6px 0 0; }
    .component--redirect-warning-popup .top .top-separator {
      width: 52px;
      height: 1px;
      background-color: #FFFFFF;
      margin-top: 13px; }
  .component--redirect-warning-popup::before {
    position: absolute;
    top: -34px;
    right: 122px;
    content: "";
    width: 30px;
    height: 50px;
    background-image: url(/static/media/tooltip-triangle-blue.e93c1576.svg);
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .component--redirect-warning-popup::after {
    position: absolute;
    top: 0px;
    right: 110px;
    content: "";
    width: 50px;
    height: 10px;
    background-color: #3A4276;
    border-radius: 3px; }
  .component--redirect-warning-popup .buttons-container {
    margin-top: 25px;
    display: flex;
    width: 100%;
    justify-content: center; }
  .component--redirect-warning-popup button {
    width: 131px;
    height: 25px;
    min-height: 25px;
    background: #F26C6C 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px #00000040;
    border-radius: 6px;
    border: none;
    color: #FFFFFF;
    font: normal 12px/12px Roboto;
    text-transform: capitalize;
    padding: 0; }
    .component--redirect-warning-popup button.continue {
      background-color: #4ACBC6;
      margin: 0 0 0 40px; }
    .component--redirect-warning-popup button.subscribe {
      background-color: #3A4377;
      margin: 22px 0 0; }
    .component--redirect-warning-popup button:hover {
      border: none; }
  .component--redirect-warning-popup .separator {
    position: relative;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    width: 360px;
    border-top: solid #EEEEEE 3px; }
  .component--redirect-warning-popup .circle {
    position: absolute;
    bottom: -17px;
    background-color: #FFFFFF;
    color: #E0E0E0;
    height: 38px;
    width: 38px;
    font: Bold 12px/12px Roboto;
    border-radius: 19px;
    border: solid #EEEEEE 3px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
  .component--redirect-warning-popup .subscribe-message {
    margin: 0 50px; }

.component--explorer-toolbar .projects-container {
  display: flex;
  width: 373px;
  line-height: 32px;
  margin: 0 67px 0 18px; }
  .component--explorer-toolbar .projects-container .project-name-container {
    position: relative;
    max-width: inherit;
    font-size: 27px;
    font-weight: 300;
    color: #333333;
    display: flex;
    cursor: pointer; }
    .component--explorer-toolbar .projects-container .project-name-container:hover .kpi-triangle,
    .component--explorer-toolbar .projects-container .project-name-container .kpi-triangle.active {
      background: url(/static/media/dropdown-triangle-hover.4dec3ec1.svg) no-repeat; }
  .component--explorer-toolbar .projects-container .project-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .component--explorer-toolbar .projects-container .button {
    justify-content: space-between;
    max-width: 210px; }
    .component--explorer-toolbar .projects-container .button .component--delete-development .icon {
      min-width: 11.3px;
      height: 12.4px;
      background-image: none; }
      .component--explorer-toolbar .projects-container .button .component--delete-development .icon:hover {
        background-image: url(/static/media/icon-trash-can-red.1c30c3f0.svg); }

.component--explorer-toolbar li:hover .button .component--delete-development .icon {
  background-image: url(/static/media/icon-trash-can-light.618a25b2.svg); }

.component--explorer-toolbar .kpis-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  overflow: hidden; }
  .component--explorer-toolbar .kpis-container .responsive-helper {
    width: 0.1px;
    height: 100%;
    float: left; }

.component--explorer-toolbar .kpi-triangle {
  background: url(/static/media/dropdown-triangle.d4ccc411.svg) no-repeat;
  min-width: 15px;
  height: 7.5px;
  margin-left: 12px;
  margin-top: 17px; }

@media screen and (max-width: 800px) {
  .component--explorer-toolbar .component--go-to-new-project-button {
    visibility: hidden; } }

.component--map-legend {
  position: relative;
  display: flex;
  align-items: flex-end; }
  .component--map-legend .expanded-legend {
    font-size: 11px;
    width: auto;
    opacity: 1;
    height: 22.3px;
    background: white;
    -webkit-transition: height .2s ease-out, opacity .1s ease-out;
    transition: height .2s ease-out, opacity .1s ease-out;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    border-radius: 3px; }
    .component--map-legend .expanded-legend.hidden {
      height: 0px;
      opacity: 0; }
    .component--map-legend .expanded-legend .legend-item {
      margin: 0px 10px; }
      .component--map-legend .expanded-legend .legend-item:first-child {
        margin-left: 11.5px; }
      .component--map-legend .expanded-legend .legend-item:last-child {
        margin-right: 11.5px; }
    .component--map-legend .expanded-legend .legend-label {
      text-transform: capitalize;
      font-weight: 400; }
    .component--map-legend .expanded-legend .legend-symbol {
      height: 8px;
      width: 18px;
      margin-right: 6px; }
  .component--map-legend .legend-toggle {
    width: 30px;
    margin-right: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    outline: none;
    background: #F5F7F8;
    display: flex;
    flex-direction: column;
    padding: 5px 6px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    border-radius: 3px; }
    .component--map-legend .legend-toggle .legend-toggle-symbol {
      height: 8px;
      width: 18px;
      margin: 3px 0px; }

.component--map {
  height: 100%;
  width: 100%;
  position: relative; }
  .component--map .map-pin {
    width: 30px; }
  .component--map .map-controls {
    position: absolute;
    display: flex;
    flex-direction: row;
    left: 120px;
    bottom: 20px; }
  .component--map button {
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: 80px;
    background-color: transparent; }
  .component--map .satellite-map-button,
  .component--map .streets-map-button {
    bottom: 20px;
    left: 20px;
    position: absolute; }
  .component--map .streets-map-button {
    background-image: url(/static/media/map-view-streets.c745370f.png); }
    .component--map .streets-map-button:hover {
      background-image: url(/static/media/map-view-streets-hover.b57c858a.png); }
  .component--map .satellite-map-button {
    background-image: url(/static/media/map-view-satellite.cdcaf37c.png); }
    .component--map .satellite-map-button:hover {
      background-image: url(/static/media/map-view-satellite-hover.fcf5ecc6.png); }

.component--slider-box {
  height: 68px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid #8E8E8E; }
  .component--slider-box.disabled {
    background-color: rgba(204, 204, 204, 0.2);
    pointer-events: none; }
    .component--slider-box.disabled + .component--slider-box.disabled.disabled, .component--slider-box.disabled:first-child {
      border-top: 1px solid #cccccc; }
    .component--slider-box.disabled ::-webkit-input-placeholder {
      color: #cccccc; }
    .component--slider-box.disabled ::-moz-placeholder {
      color: #cccccc; }
    .component--slider-box.disabled :-ms-input-placeholder {
      color: #cccccc; }
    .component--slider-box.disabled ::-ms-input-placeholder {
      color: #cccccc; }
    .component--slider-box.disabled ::placeholder {
      color: #cccccc; }
    .component--slider-box.disabled .slider-title {
      color: #cccccc; }
    .component--slider-box.disabled .slider-row-value {
      border-left: 1px solid #cccccc; }
      .component--slider-box.disabled .slider-row-value input {
        background-color: transparent; }
        .component--slider-box.disabled .slider-row-value input::-webkit-input-placeholder {
          color: #cccccc; }
        .component--slider-box.disabled .slider-row-value input::-moz-placeholder {
          color: #cccccc; }
        .component--slider-box.disabled .slider-row-value input:-ms-input-placeholder {
          color: #cccccc; }
        .component--slider-box.disabled .slider-row-value input::-ms-input-placeholder {
          color: #cccccc; }
        .component--slider-box.disabled .slider-row-value input::placeholder {
          color: #cccccc; }
  .component--slider-box:last-child {
    border-bottom: 1px solid #8E8E8E;
    height: 69px; }
    .component--slider-box:last-child.disabled {
      border-bottom: 1px solid #cccccc; }
  .component--slider-box .slider-title {
    padding: 5px 15px 0px 5px;
    color: #8E8E8E;
    font-size: 12px; }
  .component--slider-box .slider-wrapper {
    padding: 0px 15px 0px 5px; }
  .component--slider-box .slider-inner-wrapper {
    background-color: #E3E3E3;
    border-radius: 10px;
    width: 100%;
    height: 13px;
    padding-top: 0px;
    padding-left: 4px;
    padding-right: 4px;
    margin: 4px 0px 0px 0px; }
  .component--slider-box .slider-limits-wrapper {
    display: flex;
    margin-top: 7px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between; }
  .component--slider-box .slider-row-limits {
    width: 50%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7d7d7d;
    white-space: pre-wrap;
    font-size: 12px; }
    .component--slider-box .slider-row-limits input {
      padding-bottom: 3px;
      padding-top: 3px;
      height: inherit;
      width: 100%;
      background-color: transparent; }
      .component--slider-box .slider-row-limits input:focus {
        outline: 1px solid #13a7da;
        outline-offset: 0; }
    .component--slider-box .slider-row-limits:hover > input {
      outline: 1px solid #13a7da;
      outline-offset: 0; }
      .component--slider-box .slider-row-limits:hover > input::-webkit-input-placeholder {
        color: #13a7da; }
      .component--slider-box .slider-row-limits:hover > input::-moz-placeholder {
        color: #13a7da; }
      .component--slider-box .slider-row-limits:hover > input:-ms-input-placeholder {
        color: #13a7da; }
      .component--slider-box .slider-row-limits:hover > input::-ms-input-placeholder {
        color: #13a7da; }
      .component--slider-box .slider-row-limits:hover > input::placeholder {
        color: #13a7da; }
    .component--slider-box .slider-row-limits:first-child {
      justify-content: flex-start; }
      .component--slider-box .slider-row-limits:first-child input {
        padding-left: 5px;
        text-align: left; }
    .component--slider-box .slider-row-limits:last-child {
      justify-content: flex-end; }
      .component--slider-box .slider-row-limits:last-child input {
        padding-right: 15px;
        text-align: right; }
  .component--slider-box .slider-row-body {
    width: 281px; }
    .component--slider-box .slider-row-body .toggle-field-wrapper {
      align-items: center;
      display: flex;
      width: 100%;
      height: 100%;
      color: #8E8E8E; }
      .component--slider-box .slider-row-body .toggle-field-wrapper .toggle-container {
        align-items: center;
        align-content: flex-start;
        padding-left: 0px; }
        .component--slider-box .slider-row-body .toggle-field-wrapper .toggle-container .toggle-box {
          order: 2;
          margin-left: 26px;
          margin-bottom: 0; }
        .component--slider-box .slider-row-body .toggle-field-wrapper .toggle-container .toggle-label {
          margin-bottom: 0;
          order: 1;
          font-size: 12px; }
  .component--slider-box .slider-row-value {
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    border: 0px solid transparent;
    border-left: 1px solid #8E8E8E;
    white-space: pre-wrap; }
    .component--slider-box .slider-row-value input {
      width: 100%;
      height: 100%;
      font-size: 14px; }
      .component--slider-box .slider-row-value input::-webkit-input-placeholder {
        color: #333333; }
      .component--slider-box .slider-row-value input::-moz-placeholder {
        color: #333333; }
      .component--slider-box .slider-row-value input:-ms-input-placeholder {
        color: #333333; }
      .component--slider-box .slider-row-value input::-ms-input-placeholder {
        color: #333333; }
      .component--slider-box .slider-row-value input::placeholder {
        color: #333333; }
      .component--slider-box .slider-row-value input:hover {
        outline: 1px solid #13a7da;
        outline-offset: 1px; }
        .component--slider-box .slider-row-value input:hover::-webkit-input-placeholder {
          color: #13a7da; }
        .component--slider-box .slider-row-value input:hover::-moz-placeholder {
          color: #13a7da; }
        .component--slider-box .slider-row-value input:hover:-ms-input-placeholder {
          color: #13a7da; }
        .component--slider-box .slider-row-value input:hover::-ms-input-placeholder {
          color: #13a7da; }
        .component--slider-box .slider-row-value input:hover::placeholder {
          color: #13a7da; }
      .component--slider-box .slider-row-value input:focus {
        outline: 1px solid #13a7da;
        outline-offset: 1px; }
  .component--slider-box input[type=number] {
    -moz-appearance: textfield; }
  .component--slider-box input[type=number]::-webkit-inner-spin-button,
  .component--slider-box input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .component--slider-box input[type=submit] {
    display: none; }
  .component--slider-box input {
    text-align: center;
    border: none;
    color: #13a7da;
    font-size: 12px;
    width: 80%; }
    .component--slider-box input ::-webkit-input-placeholder {
      text-align: center;
      color: #333333; }
    .component--slider-box input ::-moz-placeholder {
      text-align: center;
      color: #333333; }
    .component--slider-box input :-ms-input-placeholder {
      text-align: center;
      color: #333333; }
    .component--slider-box input ::-ms-input-placeholder {
      text-align: center;
      color: #333333; }
    .component--slider-box input ::placeholder {
      text-align: center;
      color: #333333; }

.rc-slider-wrapper {
  background-color: #f5f8f9;
  border-radius: 10px;
  height: 16px;
  margin: 4px auto; }

.rc-slider {
  position: relative;
  height: 12.8px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #ACACAC;
  height: 6.4px;
  border-radius: 6px;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto 0; }

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
  top: 0px;
  bottom: 0;
  height: 7px;
  left: 0;
  right: 0;
  margin: auto 0; }

.rc-slider-handle {
  position: absolute;
  margin-left: -10px;
  margin-top: -8.5px;
  width: 20px;
  height: 20px;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: 4.5px solid #E3E3E3;
  background-color: #fff;
  box-shadow: 1.5px 1px 6px rgba(0, 0, 0, 0.5); }
  .rc-slider-handle:active {
    cursor: -webkit-grabbing;
    cursor: grabbing; }
  .rc-slider-handle:focus {
    outline: none; }

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px; }

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999; }

.rc-slider-mark-text-active {
  color: #666; }

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent; }

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle; }

.rc-slider-dot:first-child {
  margin-left: -4px; }

.rc-slider-dot:last-child {
  margin-left: -4px; }

.rc-slider-dot-active {
  border-color: #96dbfa; }

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px; }

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px; }

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  touch-action: pan-y; }

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%; }

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
  animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
  animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0); } }

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0); } }

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip-hidden {
  display: none; }

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0; }

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9; }

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c; }

.component--panel-header {
  font-size: 18px;
  font-weight: 100;
  border-bottom: 1px solid #8E8E8E;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 5px;
  margin: 0 18px; }
  .component--panel-header .link-back, .component--panel-header .pin {
    line-height: 25px;
    cursor: pointer;
    float: left; }
  .component--panel-header .pin {
    float: right; }
  .component--panel-header .icon {
    background-repeat: no-repeat;
    display: inline-block; }
    .component--panel-header .icon.icon-cross-out {
      background-image: url(/static/media/icon-cross-out.579a9698.svg);
      width: 10px;
      height: 10.2px; }
    .component--panel-header .icon.icon-pin {
      background-image: url(/static/media/icon-pin.eeb7e13f.svg);
      width: 13px;
      height: 19.8px;
      vertical-align: middle; }
      .component--panel-header .icon.icon-pin.pinned {
        background-image: url(/static/media/icon-pin-filled.2075ca94.svg); }

.component--recalculate-popup {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 550px;
  padding: 37px;
  color: #333333; }
  .component--recalculate-popup p {
    font-size: 15px; }
  .component--recalculate-popup .section-header {
    font: Bold 19px/23px Roboto Condensed;
    letter-spacing: 0;
    opacity: 1; }
  .component--recalculate-popup .bold {
    font-weight: bold; }
  .component--recalculate-popup .far-container {
    margin-bottom: 15px; }
    .component--recalculate-popup .far-container input {
      border: 2px solid #CCCCCC;
      border-radius: 8px;
      width: 100px;
      height: 37px;
      margin: 0 10px;
      text-align: center;
      font-size: 15px;
      font-family: "Roboto", sans-serif; }
      .component--recalculate-popup .far-container input:focus, .component--recalculate-popup .far-container input:hover {
        outline: none;
        outline-offset: 0; }
  .component--recalculate-popup .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 25px; }
    .component--recalculate-popup .buttons-container button {
      flex-shrink: 0;
      flex-grow: 0;
      padding: 0;
      width: 115px;
      height: 32px;
      border-radius: 16px;
      font: Bold 13px/21px Roboto; }
      .component--recalculate-popup .buttons-container button:first-child {
        margin-right: 11px; }
      .component--recalculate-popup .buttons-container button.disabled {
        background: #CCCCCC;
        cursor: none; }
      .component--recalculate-popup .buttons-container button.continue {
        width: 200px;
        background: #F65959 0% 0% no-repeat padding-box; }
        .component--recalculate-popup .buttons-container button.continue:hover {
          background-color: #3A4276; }
      .component--recalculate-popup .buttons-container button.cancel {
        color: #F26C6C;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #F26C6C; }
        .component--recalculate-popup .buttons-container button.cancel:hover {
          background-color: #F65959;
          color: white; }

.component--project-info-panel {
  height: 100%;
  border-radius: 0px 0px 5px 5px;
  -webkit-transition: height 0.3s linear, visibility 0.2s linear, opacity 0.2s linear;
  transition: height 0.3s linear, visibility 0.2s linear, opacity 0.2s linear;
  overflow: hidden; }
  .component--project-info-panel input, .component--project-info-panel select {
    border: 1px solid #cccccc;
    border-radius: 7px; }
  .component--project-info-panel input, .component--project-info-panel select {
    width: 100%;
    padding: 8px 10px;
    font-size: 17px; }
  .component--project-info-panel .recalculate-button {
    display: block;
    width: 178px;
    height: 35px;
    text-transform: capitalize;
    font: 400 15px/21px Roboto;
    letter-spacing: 0.13px;
    margin: 40px auto 0 auto;
    border-radius: 7px;
    background: #3A4276 0% 0% no-repeat padding-box; }
  .component--project-info-panel label {
    margin: 0; }
  .component--project-info-panel .project-info-accordion .accordion-content {
    padding: 26px 14px 35px;
    box-shadow: 0px 13px 25px 3px #3A427610;
    border-radius: 0 0 5px 5px; }
  .component--project-info-panel .top-label {
    display: flex;
    padding-bottom: 8px;
    font: 700 16px/21px Roboto;
    color: #3A4276;
    text-transform: uppercase;
    margin: 39px 0 10px;
    border-bottom: 1px solid #D1D5DD; }
  .component--project-info-panel .uses-container {
    color: #3A4276;
    display: flex;
    margin-top: 25px; }
    .component--project-info-panel .uses-container > :first-child {
      margin-right: 50px; }
  .component--project-info-panel .unit-system-input-container .component--unit-system-input {
    color: #3A4276;
    justify-content: space-between;
    padding: 0 40px; }
    .component--project-info-panel .unit-system-input-container .component--unit-system-input label {
      font-size: 15px; }
  .component--project-info-panel .input-field-wrapper .top-label {
    margin-bottom: 18px; }
  .component--project-info-panel .return-on-cost-wrapper {
    background: #3A4276 0% 0% no-repeat padding-box;
    box-shadow: 20px 20px 40px #0000001A;
    border-radius: 5px 5px 0px 0px;
    color: #FFFFFF;
    padding: 14px;
    height: 147px;
    display: flex;
    flex-direction: column;
    margin: 15px 18px 0; }
    .component--project-info-panel .return-on-cost-wrapper .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #FFFFFF80; }
      .component--project-info-panel .return-on-cost-wrapper .header-container .return-on-cost-label {
        font: 700 18px/32px Roboto;
        text-transform: uppercase;
        height: 35px; }
      .component--project-info-panel .return-on-cost-wrapper .header-container .open-back-of-envelope {
        cursor: pointer;
        text-decoration: underline; }
    .component--project-info-panel .return-on-cost-wrapper .content-container {
      display: flex;
      flex-grow: 1;
      padding: 0 25px;
      align-items: center; }
      .component--project-info-panel .return-on-cost-wrapper .content-container .use-section {
        min-width: 84px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 45px; }
        .component--project-info-panel .return-on-cost-wrapper .content-container .use-section:last-child {
          margin: 0; }
        .component--project-info-panel .return-on-cost-wrapper .content-container .use-section .icon {
          width: 17px;
          height: 14px;
          margin-right: 6px;
          background-repeat: no-repeat;
          background-size: cover; }
          .component--project-info-panel .return-on-cost-wrapper .content-container .use-section .icon.condo {
            background-image: url(/static/media/icon-sidebar-condo-inactive.a87cbf40.svg); }
          .component--project-info-panel .return-on-cost-wrapper .content-container .use-section .icon.rental {
            background-image: url(/static/media/icon-sidebar-multifamily-inactive.9ef00299.svg); }
          .component--project-info-panel .return-on-cost-wrapper .content-container .use-section .icon.hotel {
            background-image: url(/static/media/icon-sidebar-hotel-inactive.ff4ba18b.svg); }
      .component--project-info-panel .return-on-cost-wrapper .content-container .divisor {
        width: 100%;
        margin-bottom: 5px;
        border-bottom: 1px solid #FFF; }
      .component--project-info-panel .return-on-cost-wrapper .content-container .value {
        font: 700 20px/29px Roboto; }
      .component--project-info-panel .return-on-cost-wrapper .content-container .icon-text-container {
        display: flex;
        align-items: center;
        font: 400 11px/20px Roboto; }
  .component--project-info-panel .condensed {
    font-family: "Roboto Condensed"; }
  .component--project-info-panel select {
    height: 40px;
    background-color: #f5f7f8;
    background-image: url(/static/media/dropdown-triangle.d4ccc411.svg);
    background-repeat: no-repeat;
    background-position: center right;
    background-origin: content-box;
    background-size: 15px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    cursor: pointer;
    outline: none; }
  .component--project-info-panel .hidden {
    display: none; }
  .component--project-info-panel .errorMessage {
    display: none; }
  .component--project-info-panel.active {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    height: 710px;
    max-height: 100%; }
  .component--project-info-panel .component--panel-header {
    border-bottom: 0;
    padding-bottom: 0; }
  .component--project-info-panel .component--property-listing-tab-container {
    overflow: hidden; }
    .component--project-info-panel .component--property-listing-tab-container .content-wrapper .accordion-wrapper {
      padding: 0 18px;
      flex: 1 1 100%;
      overflow-y: overlay;
      overflow-x: hidden; }
  .component--project-info-panel .component--property-listing {
    padding: 0 18px; }
  .component--project-info-panel.panel div.panel-inner-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 0 35px; }

.component--back-of-envelope-tabs.tab-bar-wrapper {
  width: 100%;
  margin-top: 61px;
  margin-right: 61px;
  border-bottom: 1px solid #B2C5CB; }
  .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper {
    display: inline-block;
    position: relative;
    top: 1px;
    margin-left: -20px; }
    .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper.active .tab-content {
      background: white;
      color: black;
      border-bottom: 1px solid white; }
      .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper.active .tab-content:before {
        z-index: 100;
        border-bottom: 1px solid white;
        height: 105%; }
      .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper.active .tab-content:after {
        border-bottom: 1px solid white;
        height: 105%; }
    .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper:first-child :before {
      opacity: 0; }
    .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper:first-child .tab-content {
      border-top-left-radius: 0px; }
    .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper .tab-content {
      cursor: pointer;
      font-weight: bold;
      color: #8E8E8E;
      background: #F5F7F8;
      -webkit-transition: background .2s linear;
      transition: background .2s linear;
      border-top: 1px solid #B2C5CB;
      position: relative;
      display: inline-block;
      margin: 0 20px;
      width: 135px;
      padding: 9px 10px 6px 10px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 7px 7px 0 0; }
      .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper .tab-content:hover {
        background: white; }
      .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper .tab-content:before, .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper .tab-content:after {
        content: " ";
        position: absolute;
        top: -1px;
        width: 25px;
        height: 103%;
        background-color: inherit; }
      .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper .tab-content:before {
        border-radius: 12px 0 0 0;
        border-left: 1px solid #B2C5CB;
        border-top: 1px solid #B2C5CB;
        -webkit-transform: skew(-24deg);
                transform: skew(-24deg);
        left: -13px; }
      .component--back-of-envelope-tabs.tab-bar-wrapper .tab-wrapper .tab-content:after {
        border-radius: 0 12px 0 0;
        -webkit-transform: skew(24deg);
                transform: skew(24deg);
        right: -13px;
        border-right: 1px solid #B2C5CB;
        border-top: 1px solid #B2C5CB;
        z-index: 100; }

.component--back-of-envelope td {
  font-family: "Roboto Condensed";
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px; }

.component--back-of-envelope .sheet-wrapper {
  width: 100%; }

.component--back-of-envelope .break {
  height: 70px; }

.component--remove-setback-popup {
  width: 360px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #FFFFFF;
  font: 400 13px/16px Roboto;
  text-align: center;
  border-radius: 6px; }
  .component--remove-setback-popup .top {
    background-color: #3A4276;
    padding: 20px 18px 12px;
    color: #FFFFFF;
    border-radius: 6px 6px 0 0; }
    .component--remove-setback-popup .top .separator {
      margin-top: 9px;
      height: 1px;
      background-color: #FFFFFF80; }
    .component--remove-setback-popup .top .italic {
      margin-top: 9px;
      font: Italic 12px/21px Roboto; }
    .component--remove-setback-popup .top .bold {
      font-weight: 700; }
  .component--remove-setback-popup .bottom {
    padding: 20px 0 25px; }
  .component--remove-setback-popup .buttons-container {
    display: flex;
    width: 100%;
    justify-content: center; }
  .component--remove-setback-popup button {
    width: 131px;
    height: 25px;
    min-height: 25px;
    box-shadow: 0px 4px 10px #00000040;
    border-radius: 6px;
    border: none;
    color: #FFFFFF;
    font: 700 13px/13px Roboto;
    padding: 0; }
    .component--remove-setback-popup button.cancel {
      background-color: #3A4276; }
    .component--remove-setback-popup button.confirm {
      background-color: #F26C6C;
      margin-left: 35px; }
    .component--remove-setback-popup button:hover {
      border: none; }

.height-setbacks .setback-mode-title {
  margin-top: 55px; }

.height-setbacks .text-box {
  color: #333333;
  margin: 10.5px 0 18px 0;
  font-size: 13px; }

.height-setbacks .button-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 53px; }
  .height-setbacks .button-wrapper button {
    border-radius: 6px; }
  .height-setbacks .button-wrapper .component--dropdown {
    width: 180px;
    border-bottom: 0.5px solid #8E8E8E;
    outline: none;
    margin-left: 5px; }
    .height-setbacks .button-wrapper .component--dropdown .floor-toggle-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer; }
      .height-setbacks .button-wrapper .component--dropdown .floor-toggle-button .kpi-triangle {
        background: url(/static/media/dropdown-triangle.d4ccc411.svg) no-repeat;
        min-width: 12px;
        height: 6px;
        margin-top: 5px; }
    .height-setbacks .button-wrapper .component--dropdown .dropdown-content {
      top: 33px;
      right: 246px;
      width: 195px; }
      .height-setbacks .button-wrapper .component--dropdown .dropdown-content li {
        padding: 0 10px; }
        .height-setbacks .button-wrapper .component--dropdown .dropdown-content li.inactive-divisor {
          padding: 0 10px;
          display: flex;
          margin: 0 10px;
          justify-content: center;
          align-items: center;
          height: 35px;
          color: #fff;
          border-top: 1px solid #fff;
          cursor: inherit; }
          .height-setbacks .button-wrapper .component--dropdown .dropdown-content li.inactive-divisor:hover {
            background-color: inherit; }
            .height-setbacks .button-wrapper .component--dropdown .dropdown-content li.inactive-divisor:hover + li .button {
              border-color: #3A4377; }
      .height-setbacks .button-wrapper .component--dropdown .dropdown-content .button {
        justify-content: space-between; }
        .height-setbacks .button-wrapper .component--dropdown .dropdown-content .button.with-setback {
          color: #F9CB58; }
        .height-setbacks .button-wrapper .component--dropdown .dropdown-content .button.inactive {
          color: #D5D5E0; }
          .height-setbacks .button-wrapper .component--dropdown .dropdown-content .button.inactive .remove-setback {
            background-image: url(/static/media/icon-trash-can-light.618a25b2.svg); }
  .height-setbacks .button-wrapper .remove-setback {
    margin-right: 3px;
    min-width: 12px;
    height: 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(/static/media/icon-trash-can-yellow.d71f3640.svg); }
  .height-setbacks .button-wrapper button {
    width: 148px;
    height: 34px;
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    padding: 0; }
    .height-setbacks .button-wrapper button:hover:not(.active) {
      background-color: #3A4276; }
    .height-setbacks .button-wrapper button.active {
      background-color: transparent;
      color: #666666;
      border: 0;
      box-shadow: inset 0px 0px 0px 1.5px #F26C6C; }
      .height-setbacks .button-wrapper button.active:hover {
        color: #F26C6C; }

.component--from-contact-details {
  display: flex;
  align-items: center;
  margin-top: 25px; }
  .component--from-contact-details .label-container {
    height: 68px;
    border-right: 1px solid #F26C6C; }
    .component--from-contact-details .label-container label {
      font: Bold 15px Roboto;
      text-transform: uppercase;
      margin: 8px 0 0 0;
      color: #F26C6C; }
    .component--from-contact-details .label-container .contact-icon {
      width: 28px;
      height: 28px;
      background: url(/static/media/icon-contact-red.f2cc04af.svg) no-repeat;
      background-size: contain; }
  .component--from-contact-details .inputs-container {
    width: 100%; }
    .component--from-contact-details .inputs-container input {
      width: 100%;
      height: 21px;
      border: 1px solid #CCCCCC;
      box-shadow: 2px 2px 5px #0000000D;
      margin-bottom: 4px;
      border-radius: 3px;
      padding-left: 8px;
      font: normal 12px/31px Roboto;
      text-transform: capitalize;
      outline: none; }
      .component--from-contact-details .inputs-container input ::-webkit-input-placeholder {
        color: #ABAFB3; }
      .component--from-contact-details .inputs-container input ::-moz-placeholder {
        color: #ABAFB3; }
      .component--from-contact-details .inputs-container input :-ms-input-placeholder {
        color: #ABAFB3; }
      .component--from-contact-details .inputs-container input ::-ms-input-placeholder {
        color: #ABAFB3; }
      .component--from-contact-details .inputs-container input ::placeholder {
        color: #ABAFB3; }
    .component--from-contact-details .inputs-container .with-label {
      display: flex; }
      .component--from-contact-details .inputs-container .with-label .input-label {
        width: 21px;
        height: 21px;
        border-radius: 3px 0 0 3px;
        background-repeat: no-repeat;
        background-size: 7px 12px;
        background-position: center; }
        .component--from-contact-details .inputs-container .with-label .input-label.phone-icon {
          background-color: #F26C6C;
          background-image: url(/static/media/icon-phone-white.7060c16f.svg); }
        .component--from-contact-details .inputs-container .with-label .input-label.email-icon {
          background-color: #F26C6C;
          background-image: url(/static/media/icon-at-white.91bdda1c.svg); }
      .component--from-contact-details .inputs-container .with-label input {
        border-radius: 0 3px 3px 0;
        border-left: 0; }
    .component--from-contact-details .inputs-container .email {
      text-transform: none; }
    .component--from-contact-details .inputs-container .phone-container input {
      margin-bottom: 0; }

.component--to-contact-details {
  display: flex;
  align-items: center;
  margin-top: 25px; }
  .component--to-contact-details .label-container {
    height: 68px;
    border-right: 1px solid #59608D; }
    .component--to-contact-details .label-container label {
      font: Bold 15px Roboto;
      text-transform: uppercase;
      margin: 8px 0 0 0;
      color: #59608D; }
    .component--to-contact-details .label-container .contact-icon {
      width: 28px;
      height: 28px;
      background: url(/static/media/icon-contact-blue.55bd91d3.svg) no-repeat;
      background-size: contain; }
  .component--to-contact-details .inputs-container {
    width: 100%; }
    .component--to-contact-details .inputs-container input {
      width: 100%;
      height: 21px;
      border: 1px solid #CCCCCC;
      box-shadow: 2px 2px 5px #0000000D;
      margin-bottom: 4px;
      border-radius: 3px;
      padding-left: 8px;
      font: normal 12px/31px Roboto;
      text-transform: capitalize;
      outline: none; }
      .component--to-contact-details .inputs-container input ::-webkit-input-placeholder {
        color: #ABAFB3; }
      .component--to-contact-details .inputs-container input ::-moz-placeholder {
        color: #ABAFB3; }
      .component--to-contact-details .inputs-container input :-ms-input-placeholder {
        color: #ABAFB3; }
      .component--to-contact-details .inputs-container input ::-ms-input-placeholder {
        color: #ABAFB3; }
      .component--to-contact-details .inputs-container input ::placeholder {
        color: #ABAFB3; }
    .component--to-contact-details .inputs-container .with-label {
      display: flex; }
      .component--to-contact-details .inputs-container .with-label .input-label {
        width: 21px;
        height: 21px;
        border-radius: 3px 0 0 3px;
        background-repeat: no-repeat;
        background-size: 7px 12px;
        background-position: center; }
        .component--to-contact-details .inputs-container .with-label .input-label.phone-icon {
          background-color: #59608D;
          background-image: url(/static/media/icon-phone-white.7060c16f.svg); }
        .component--to-contact-details .inputs-container .with-label .input-label.email-icon {
          background-color: #59608D;
          background-image: url(/static/media/icon-at-white.91bdda1c.svg); }
      .component--to-contact-details .inputs-container .with-label input {
        border-radius: 0 3px 3px 0;
        border-left: 0; }
    .component--to-contact-details .inputs-container .email {
      text-transform: none; }
    .component--to-contact-details .inputs-container .phone-container input {
      margin-bottom: 0; }

.component--summary {
  margin-top: 40px;
  display: flex; }
  .component--summary .label-container {
    font: Bold 12px Roboto; }
    .component--summary .label-container .summary-label {
      width: 25px;
      height: 30px;
      margin-bottom: 4px;
      background: url(/static/media/icon-summary.2ca356af.svg) no-repeat; }
  .component--summary .text-container {
    height: 98px;
    width: 100%;
    display: flex;
    flex-direction: column; }
    .component--summary .text-container .summary-text {
      line-height: 13px;
      padding: 8px;
      outline: none;
      height: 84px;
      resize: none;
      width: 100%;
      border-radius: 3px;
      border: 1px solid #CCCCCC;
      font: normal 12px/13px Roboto; }
      .component--summary .text-container .summary-text ::-webkit-input-placeholder {
        color: #ABAFB3; }
      .component--summary .text-container .summary-text ::-moz-placeholder {
        color: #ABAFB3; }
      .component--summary .text-container .summary-text :-ms-input-placeholder {
        color: #ABAFB3; }
      .component--summary .text-container .summary-text ::-ms-input-placeholder {
        color: #ABAFB3; }
      .component--summary .text-container .summary-text ::placeholder {
        color: #ABAFB3; }
    .component--summary .text-container .warning-message {
      font: Italic 9px/14px Roboto;
      color: #E86969;
      align-self: flex-end; }

.component--drag-and-drop {
  box-sizing: content-box; }
  .component--drag-and-drop .drop-container {
    position: relative;
    border-radius: 3px;
    border: 0.5px dashed #CCCCCC;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .component--drag-and-drop .drop-container.drag-over {
      border-color: black; }
    .component--drag-and-drop .drop-container .upload-logo {
      display: flex;
      align-items: flex-end;
      background: url(/static/media/icon-upload.3f898a81.svg) no-repeat;
      background-position: center;
      background-size: 25px 25px;
      height: 45px;
      font: normal 9px/10px Roboto; }

.component--image-upload {
  display: flex;
  margin-top: 35px; }
  .component--image-upload .label-container .icon {
    width: 23px;
    height: 23px;
    background-size: contain;
    background-repeat: no-repeat; }
  .component--image-upload .label-container .cover-icon {
    background-image: url(/static/media/icon-upload-cover.55de1aa1.svg); }
  .component--image-upload .label-container .logo-icon {
    background-image: url(/static/media/icon-upload-logo.a979cdbb.svg); }
  .component--image-upload .drag-and-drop-container {
    border: 0.5px solid #CCCCCC;
    border-radius: 3px;
    flex: 1 1 auto;
    padding: 8px 7px 5px;
    display: flex;
    flex-direction: column; }
    .component--image-upload .drag-and-drop-container .component--busy-spinner {
      flex: 1 1 50px; }
    .component--image-upload .drag-and-drop-container .title {
      font: Bold 12px Roboto; }
    .component--image-upload .drag-and-drop-container .file-name {
      position: relative;
      color: #59608D;
      border: 1px dashed #59608D;
      padding: 5px 15px 5px 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      font: normal 12px/12px Roboto; }
      .component--image-upload .drag-and-drop-container .file-name .file-remove {
        position: absolute;
        top: 6px;
        right: 3px;
        width: 10px;
        height: 10px;
        border: 1px solid #F26C6C;
        border-radius: 5px;
        cursor: pointer;
        background: url(/static/media/icon-cross-out.579a9698.svg) no-repeat;
        background-size: 4px 4px;
        background-position: center; }

.component--color-palette {
  display: flex;
  margin-top: 40px;
  position: relative; }
  .component--color-palette .palette-icon {
    width: 25px;
    height: 25px;
    background: url(/static/media/icon-color-palette.bb1d2db6.svg) no-repeat;
    background-size: contain; }
  .component--color-palette .color-selection-container {
    display: flex;
    flex: 1 1 100%;
    justify-content: space-evenly;
    height: 95px;
    border: 1px solid #CCCCCC;
    border-radius: 3px; }
    .component--color-palette .color-selection-container .color-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 auto;
      padding-top: 12px; }
      .component--color-palette .color-selection-container .color-section label {
        margin: 0;
        font: 500 10px/13px Roboto; }
      .component--color-palette .color-selection-container .color-section .color-preview {
        margin-top: 20px;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        cursor: pointer;
        box-shadow: 0px 3px 6px #00000029; }
      .component--color-palette .color-selection-container .color-section .pick-color {
        font: normal 8px/13px Roboto;
        color: #8D8D8D;
        margin-top: 5px; }
  .component--color-palette .color-picker-wrapper {
    position: absolute;
    top: -60px;
    left: 30%; }
    .component--color-palette .color-picker-wrapper .cover {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
    .component--color-palette .color-picker-wrapper .close-color-picker {
      position: absolute;
      top: -20px;
      left: 2px;
      cursor: pointer; }

.component--pdf-panel {
  color: #464A53; }
  .component--pdf-panel .panel-inner-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column; }
    .component--pdf-panel .panel-inner-wrapper #export-pdf-button {
      text-transform: uppercase;
      padding: 0;
      width: 211px;
      height: 31px;
      font: Bold 13px/21px Roboto;
      letter-spacing: 0.13px;
      align-self: center;
      margin: 45px 0 40px;
      flex-shrink: 0; }
  .component--pdf-panel .report-title {
    border: none;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 4px;
    font: normal 15px/18px Roboto;
    outline: none;
    margin-top: 32px; }
    .component--pdf-panel .report-title ::-webkit-input-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .report-title ::-moz-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .report-title :-ms-input-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .report-title ::-ms-input-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .report-title ::placeholder {
      color: #CCCCCC; }
  .component--pdf-panel .address {
    border: none;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 4px;
    font: normal 15px/18px Roboto;
    outline: none;
    margin-top: 18px; }
    .component--pdf-panel .address ::-webkit-input-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .address ::-moz-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .address :-ms-input-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .address ::-ms-input-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .address ::placeholder {
      color: #CCCCCC; }
  .component--pdf-panel .city,
  .component--pdf-panel .state,
  .component--pdf-panel .zip-code {
    border: none;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 4px;
    font: normal 15px/18px Roboto;
    outline: none;
    width: 100px; }
    .component--pdf-panel .city ::-webkit-input-placeholder,
    .component--pdf-panel .state ::-webkit-input-placeholder,
    .component--pdf-panel .zip-code ::-webkit-input-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .city ::-moz-placeholder,
    .component--pdf-panel .state ::-moz-placeholder,
    .component--pdf-panel .zip-code ::-moz-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .city :-ms-input-placeholder,
    .component--pdf-panel .state :-ms-input-placeholder,
    .component--pdf-panel .zip-code :-ms-input-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .city ::-ms-input-placeholder,
    .component--pdf-panel .state ::-ms-input-placeholder,
    .component--pdf-panel .zip-code ::-ms-input-placeholder {
      color: #CCCCCC; }
    .component--pdf-panel .city ::placeholder,
    .component--pdf-panel .state ::placeholder,
    .component--pdf-panel .zip-code ::placeholder {
      color: #CCCCCC; }
  .component--pdf-panel .city {
    flex: 2 1; }
  .component--pdf-panel .state,
  .component--pdf-panel .zip-code {
    flex: 1 1; }
  .component--pdf-panel .address-container {
    display: flex;
    margin-top: 18px; }
    .component--pdf-panel .address-container input {
      margin-right: 20px;
      flex: 1 0 auto; }
      .component--pdf-panel .address-container input:last-child {
        margin-right: 0; }
  .component--pdf-panel .label-container {
    margin-right: 20px;
    width: 80px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .component--pdf-panel .label-container label {
      font: Bold 12px Roboto;
      margin: 4px 0 0; }
  .component--pdf-panel .dimension-message {
    font: 200 Italic 10px/11px Roboto;
    text-align: right;
    margin-top: 5px;
    color: #8D8D8D; }

.panels-wrapper {
  max-width: 100%;
  flex-shrink: 0;
  overflow-x: auto;
  display: flex;
  z-index: 100; }
  .panels-wrapper .panel {
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    flex-shrink: 0; }
    .panels-wrapper .panel:not(.component--back-of-envelope) {
      width: 476px; }
    .panels-wrapper .panel:nth-child(n+1) {
      border-left: 1px solid #e7ebed; }
    .panels-wrapper .panel .panel-inner-wrapper {
      background-color: white;
      padding: 0 18px;
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: auto;
      overflow-y: overlay;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      padding-bottom: 68px; }
      .panels-wrapper .panel .panel-inner-wrapper .panel-section-title {
        padding: 10px 0px 10px 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 18px;
        font-weight: bold;
        margin-top: 25px; }
        .panels-wrapper .panel .panel-inner-wrapper .panel-section-title.render-own-border {
          border-bottom: 1px solid #8E8E8E; }
        .panels-wrapper .panel .panel-inner-wrapper .panel-section-title .title {
          margin-top: -36px; }
        .panels-wrapper .panel .panel-inner-wrapper .panel-section-title h1 {
          font-size: inherit;
          font-weight: inherit;
          flex: 2 2; }
        .panels-wrapper .panel .panel-inner-wrapper .panel-section-title span {
          text-align: center;
          flex: 1 1;
          font-size: 14px;
          font-weight: inherit; }

.component--setback-icon {
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer; }
  .component--setback-icon:hover, .component--setback-icon.selected {
    width: 45px;
    height: 45px; }
  .component--setback-icon.A {
    background-image: url(/static/media/setback-button-a.b4e5e1a0.svg); }
    .component--setback-icon.A.selected {
      background-image: url(/static/media/setback-button-a-selected.54476fc3.svg); }
  .component--setback-icon.B {
    background-image: url(/static/media/setback-button-b.7942a41e.svg); }
    .component--setback-icon.B.selected {
      background-image: url(/static/media/setback-button-b-selected.9ccea4bc.svg); }
  .component--setback-icon.C {
    background-image: url(/static/media/setback-button-c.cdfaf04d.svg); }
    .component--setback-icon.C.selected {
      background-image: url(/static/media/setback-button-c-selected.f6321b99.svg); }
  .component--setback-icon.D {
    background-image: url(/static/media/setback-button-d.b81ad38f.svg); }
    .component--setback-icon.D.selected {
      background-image: url(/static/media/setback-button-d-selected.b5ebd90d.svg); }

.close-cross {
  background-image: url(/static/media/white-cross.fdc9049a.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 100px;
  right: 50px;
  width: 27.4px;
  height: 29.07px;
  cursor: pointer; }

.floor {
  position: absolute;
  top: 20px;
  right: 165px;
  height: 75px;
  width: 215px;
  background-color: #F26C6C;
  font: 900 30px/75px Roboto;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.component--select-setback {
  background-color: #FFFFFF;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  top: 115px;
  right: 160px;
  width: 225px; }
  .component--select-setback .content {
    width: 100%;
    height: 100%;
    padding: 7px 14.5px 0 15.5px; }
  .component--select-setback .setback-list-container {
    position: relative;
    padding: 19px 0 10px 0; }
  .component--select-setback .setback-row {
    width: 100%;
    margin-bottom: 3px; }
  .component--select-setback .header {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    border-bottom: 1px solid #CCCCCC; }
  .component--select-setback .text {
    font-family: inherit;
    font-size: 13px;
    color: #666666;
    line-height: 18px;
    float: left;
    width: 160px; }
  .component--select-setback input[type="radio"] {
    position: relative;
    top: 4px;
    float: right;
    border-radius: 50%;
    -webkit-appearance: none;
    border: 1px solid #777777;
    width: 11px;
    height: 11px;
    outline: none; }
    .component--select-setback input[type="radio"]:checked {
      background-color: #F26C6C;
      box-shadow: inset 0px 0px 0px 1px #FFFFFF; }

.component--thumbnail-map {
  position: fixed;
  right: -10000px;
  bottom: -10000px;
  z-index: -100; }

.component--explorer-sidebar {
  overflow-y: auto;
  overflow-x: hidden; }
  .component--explorer-sidebar .component--sidebar {
    height: 100%;
    background-color: #3A4276; }
    .component--explorer-sidebar .component--sidebar .title-wrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #EBECF1;
      padding-bottom: 3px;
      align-items: flex-end; }
      .component--explorer-sidebar .component--sidebar .title-wrapper h5 {
        border-bottom: none;
        padding: 0; }
      .component--explorer-sidebar .component--sidebar .title-wrapper .saved-message {
        height: 13px;
        width: 46px;
        background-color: #F9CB58;
        color: #3A4276;
        font: 500 8px/13px Roboto;
        text-align: center;
        border-radius: 5px; }
    .component--explorer-sidebar .component--sidebar h5 {
      color: #EBECF1;
      border-bottom: 1px solid #EBECF1; }
    .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links {
      color: #EBECF1;
      text-decoration: none; }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-dashboard {
        background-image: url(/static/media/icon-sidebar-dashboard-inactive.4d02759c.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-project_info_panel {
        background-image: url(/static/media/icon-sidebar-project-info-inactive.2306dee6.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-constraints_panel {
        background-image: url(/static/media/icon-sidebar-building-constraint-inactive.7b280fd7.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-cost_panel {
        background-image: url(/static/media/icon-sidebar-project-cost-inactive.500f4f76.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-condo_panel {
        background-image: url(/static/media/icon-sidebar-condo-inactive.a87cbf40.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-multifamily_panel {
        background-image: url(/static/media/icon-sidebar-multifamily-inactive.9ef00299.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-hotel_panel {
        background-image: url(/static/media/icon-sidebar-hotel-inactive.ff4ba18b.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-office_panel {
        background-image: url(/static/media/icon-sidebar-office-inactive.418d64cf.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-retail_panel {
        background-image: url(/static/media/icon-sidebar-retail-inactive.bd1fc4ca.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-support {
        background-image: url(/static/media/icon-sidebar-support-inactive.edfdf384.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-back_of_envelope {
        background-image: url(/static/media/icon-sidebar-back-of-envelope-inactive.941120e4.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-pdf_panel {
        background-image: url(/static/media/icon-sidebar-pdf-report-inactive.97908880.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-industrial_panel {
        background-image: url(/static/media/icon-sidebar-industrial-inactive.c82e28e3.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links .icon-parking_panel {
        background-image: url(/static/media/icon-sidebar-parking-inactive.afaecc64.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover {
        background-color: #3A4377;
        color: #EBECF1;
        border-radius: 5px; }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-dashboard, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-dashboard {
          background-image: url(/static/media/icon-sidebar-dashboard-active.63474d92.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-project_info_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-project_info_panel {
          background-image: url(/static/media/icon-sidebar-project-info-active.00bec4e8.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-constraints_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-constraints_panel {
          background-image: url(/static/media/icon-sidebar-building-constraint-active.1ff24bf4.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-cost_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-cost_panel {
          background-image: url(/static/media/icon-sidebar-project-cost-active.80e81c5d.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-condo_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-condo_panel {
          background-image: url(/static/media/icon-sidebar-condo-active.7c17342e.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-multifamily_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-multifamily_panel {
          background-image: url(/static/media/icon-sidebar-multifamily-active.46110b43.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-hotel_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-hotel_panel {
          background-image: url(/static/media/icon-sidebar-hotel-active.3d6b8312.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-office_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-office_panel {
          background-image: url(/static/media/icon-sidebar-office-active.6b52d226.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-retail_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-retail_panel {
          background-image: url(/static/media/icon-sidebar-retail-active.5d1c5b31.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-support, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-support {
          background-image: url(/static/media/icon-sidebar-support-active.823420b9.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-back_of_envelope, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-back_of_envelope {
          background-image: url(/static/media/icon-sidebar-back-of-envelope-active.cb0b2d54.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-pdf_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-pdf_panel {
          background-image: url(/static/media/icon-sidebar-pdf-report-active.c506ffae.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-industrial_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-industrial_panel {
          background-image: url(/static/media/icon-sidebar-industrial-active.9d89aa16.svg); }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.active .icon-parking_panel, .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links:not(.panel-disabled):hover .icon-parking_panel {
          background-image: url(/static/media/icon-sidebar-parking-active.0eb244a3.svg); }
      .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.disabled {
        color: #c0c0c0;
        background-color: transparent;
        pointer-events: none; }
        .component--explorer-sidebar .component--sidebar .sidebar-nav .side-bar-links.disabled .icon-pdf_panel {
          background-image: url(/static/media/icon-sidebar-pdf-report-disabled.867d0971.svg); }

.component--keep-project {
  position: absolute;
  top: 155px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 14px 10px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2); }
  .component--keep-project .close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 22px;
    height: 22px;
    background-image: url(/static/media/icon-close-transparent.4205c45e.svg);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer; }
  .component--keep-project .icon-building {
    width: 32px;
    height: 42px;
    background-image: url(/static/media/icon-building.a558fb52.svg);
    background-size: contain;
    background-repeat: no-repeat; }
  .component--keep-project .text {
    margin-top: 8px;
    font: 300 14px/14px Roboto;
    color: #999999; }
  .component--keep-project .separator {
    margin-top: 8px;
    width: 32px;
    height: 1px;
    background-color: #59618D; }
  .component--keep-project .project-name {
    width: 180px;
    margin-top: 10px;
    font: 900 15px/15px Roboto;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden; }
  .component--keep-project button {
    width: 94px;
    height: 27px;
    border-radius: 3px;
    background-color: #3A4276;
    margin-top: 18px;
    font: 400 12px/12px Roboto;
    letter-spacing: 0.18px; }
  .component--keep-project .underlined-text {
    margin-top: 6px;
    font: 300 12px/12px Roboto;
    color: #999999;
    text-decoration: underline;
    cursor: pointer; }

.component--explorer-page {
  display: grid;
  height: 100vh;
  width: 100vw;
  min-width: 1086px;
  overflow-x: auto;
  overflow-y: hidden;
  grid-template-columns: 195px 1fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas: "toolbar toolbar" "sidebar content"; }
  .component--explorer-page .component--explorer-toolbar {
    grid-area: toolbar; }
  .component--explorer-page .component--sidebar {
    grid-area: sidebar; }
  .component--explorer-page .explorer-content {
    position: relative;
    grid-area: content;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden; }
  .component--explorer-page .building-alert {
    position: absolute;
    top: 89px;
    right: 320px;
    display: flex;
    align-items: center;
    background-color: #F26C6C;
    z-index: 2100;
    height: 36px;
    width: 700px;
    font: 400 13px/36px Roboto;
    color: #FFFFFF;
    padding: 0 15px;
    border-radius: 3px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2); }
    .component--explorer-page .building-alert .icon-info {
      height: 13px;
      width: 13px;
      margin: 0 10px 2px 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(/static/media/icon-information-white.685065ae.svg); }
    .component--explorer-page .building-alert .icon-close {
      position: absolute;
      top: -8px;
      right: -8px;
      height: 18px;
      width: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(/static/media/icon-close.9fe293b3.svg);
      cursor: pointer; }

.component--forgot-password-page {
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: #F6F6F6;
  min-height: 100%; }
  .component--forgot-password-page .hidden {
    display: none; }
  .component--forgot-password-page a {
    font: inherit;
    font-weight: bold;
    color: #3A4276; }
  .component--forgot-password-page button {
    background-color: #3A4276;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer; }
  .component--forgot-password-page .underlined-link {
    font-weight: 500;
    color: #333333;
    text-decoration: underline;
    cursor: pointer; }
    .component--forgot-password-page .underlined-link:hover {
      color: #3A4276;
      text-decoration: underline; }
  .component--forgot-password-page .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .component--forgot-password-page .content-wrapper {
    position: absolute;
    top: 241px; }
  .component--forgot-password-page .header {
    font-size: 25px;
    font-family: "Roboto Condensed";
    font-weight: 700; }
  .component--forgot-password-page .container {
    width: 395px;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    padding: 35px 30px 38px;
    box-shadow: 0px 8px 20px #00000017;
    border-radius: 10px; }
  .component--forgot-password-page .content-header {
    flex-shrink: 0;
    width: 240.5px;
    height: 39.5px;
    background-image: url(/static/media/logo-inline.dd1ad572.svg);
    background-repeat: no-repeat;
    background-size: contain; }
  .component--forgot-password-page .text-box {
    height: auto;
    width: auto;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    line-height: 19px; }
  .component--forgot-password-page .form-container + .text-box {
    margin-top: 33px; }
  .component--forgot-password-page .form-container .input-section {
    width: auto;
    margin-bottom: 16px; }
    .component--forgot-password-page .form-container .input-section .input-label {
      width: auto;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin: 15px 0 5px 10px;
      line-height: 10px; }
    .component--forgot-password-page .form-container .input-section .input-field {
      position: relative;
      height: 32px;
      width: 335px;
      background-color: #FDFDFD;
      padding: 4px 13px 4px 4px;
      border: 1px solid #CCCCCC;
      border-radius: 8px; }
      .component--forgot-password-page .form-container .input-section .input-field input {
        border: none;
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 15px;
        font-family: inherit;
        font-size: 15px;
        background-color: transparent; }
        .component--forgot-password-page .form-container .input-section .input-field input:-webkit-autofill {
          border: none;
          box-shadow: 0 0 0px 1000px white inset; }
        .component--forgot-password-page .form-container .input-section .input-field input::-webkit-input-placeholder {
          color: #CCCCCC; }
        .component--forgot-password-page .form-container .input-section .input-field input::-moz-placeholder {
          color: #CCCCCC; }
        .component--forgot-password-page .form-container .input-section .input-field input:-ms-input-placeholder {
          color: #CCCCCC; }
        .component--forgot-password-page .form-container .input-section .input-field input::-ms-input-placeholder {
          color: #CCCCCC; }
        .component--forgot-password-page .form-container .input-section .input-field input::placeholder {
          color: #CCCCCC; }
      .component--forgot-password-page .form-container .input-section .input-field.required:after {
        content: "*";
        position: absolute;
        top: 5px;
        right: 8px;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: bold;
        color: #F26C6C; }
      .component--forgot-password-page .form-container .input-section .input-field.dropdown-arrow {
        background-image: url(/static/media/dropdown-triangle.d4ccc411.svg);
        background-repeat: no-repeat;
        background-position: center right;
        background-origin: content-box;
        background-size: 15px;
        content: "";
        display: block;
        padding-right: 7.5px;
        cursor: pointer; }
        .component--forgot-password-page .form-container .input-section .input-field.dropdown-arrow input {
          cursor: pointer; }
        .component--forgot-password-page .form-container .input-section .input-field.dropdown-arrow:hover {
          background-image: url(/static/media/dropdown-triangle-hover.4dec3ec1.svg); }
  .component--forgot-password-page .form-container .submit {
    height: 35px;
    width: 97px;
    letter-spacing: 0.25px;
    padding: 0;
    pointer-events: all; }
    .component--forgot-password-page .form-container .submit.disabled {
      cursor: inherit;
      color: white; }
  .component--forgot-password-page .bold {
    font-weight: bold; }
  .component--forgot-password-page .error-message {
    display: flex;
    align-items: flex-end;
    height: 31px;
    margin: 25px 0 13px 5px;
    width: 294px;
    text-align: left;
    vertical-align: middle;
    font-family: "Roboto Condensed", "Regular";
    font-size: 13px;
    color: #F26C6C;
    line-height: 16px; }
  .component--forgot-password-page .reset-password {
    margin-top: 33px; }
  .component--forgot-password-page .tos-agreement {
    height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 36px; }
    .component--forgot-password-page .tos-agreement input[type="checkbox"] {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background-color: #F5F7F8;
      border: 1px solid #CCCCCC;
      padding: 6.5px;
      border-radius: 5px;
      width: 0;
      height: 0; }
      .component--forgot-password-page .tos-agreement input[type="checkbox"]:checked {
        border: 1px solid #333333;
        background: url(/static/media/checkmark.e3d7ea53.svg);
        background-size: contain;
        background-repeat: no-repeat; }
      .component--forgot-password-page .tos-agreement input[type="checkbox"]:focus {
        outline: none; }
    .component--forgot-password-page .tos-agreement .text-box {
      margin-left: 14px; }
  @media screen and (max-width: 794px) {
    .component--forgot-password-page .flex-wrapper {
      flex-direction: column-reverse; } }
  .component--forgot-password-page form {
    display: flex;
    justify-content: center; }
  .component--forgot-password-page .content-wrapper .header {
    text-align: center;
    margin-bottom: 53px; }
  .component--forgot-password-page .content-wrapper .text-box {
    font-size: 16px;
    width: 415px;
    text-align: center;
    font-family: "Roboto Condensed";
    margin-bottom: 36px; }
    .component--forgot-password-page .content-wrapper .text-box.email-sent {
      width: 465px; }
  .component--forgot-password-page .form-container .input-section {
    width: 284px;
    margin-right: 20px; }
    .component--forgot-password-page .form-container .input-section .input-field {
      width: inherit; }
  .component--forgot-password-page .form-container input {
    width: 100%; }
  .component--forgot-password-page .form-container .submit {
    padding: 0px 31px; }

.component--parcel-tools {
  background: #3A4377;
  border-radius: 0 0 8px 0;
  display: flex;
  align-items: center;
  padding-right: 9px; }
  .component--parcel-tools .control-icon {
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 9px;
    height: 31.6px;
    width: 30px;
    cursor: pointer; }
    .component--parcel-tools .control-icon.disabled {
      pointer-events: none; }
  .component--parcel-tools .select-parcel {
    background-image: url(/static/media/parcel-tools-select-parcel.9303c559.svg); }
    .component--parcel-tools .select-parcel:hover, .component--parcel-tools .select-parcel.active {
      background-image: url(/static/media/parcel-tools-select-parcel-hover.f4c70ec2.svg); }
  .component--parcel-tools .combine-parcels {
    background-image: url(/static/media/parcel-tools-combine-parcels.5c72ddc0.svg); }
    .component--parcel-tools .combine-parcels:hover, .component--parcel-tools .combine-parcels.active {
      background-image: url(/static/media/parcel-tools-combine-parcels-hover.2ac3402f.svg); }
  .component--parcel-tools .draw-parcel {
    background-image: url(/static/media/parcel-tools-draw-parcel.78076254.svg); }
    .component--parcel-tools .draw-parcel:hover, .component--parcel-tools .draw-parcel.active {
      background-image: url(/static/media/parcel-tools-draw-parcel-hover.db926ea3.svg); }
  .component--parcel-tools .edit-parcel {
    background-image: url(/static/media/parcel-tools-edit-parcel.983dd997.svg); }
    .component--parcel-tools .edit-parcel:hover, .component--parcel-tools .edit-parcel.active {
      background-image: url(/static/media/parcel-tools-edit-parcel-hover.e131497a.svg); }
  .component--parcel-tools .delete-parcel {
    background-image: url(/static/media/parcel-tools-delete-parcel.37e850ca.svg); }
    .component--parcel-tools .delete-parcel:hover, .component--parcel-tools .delete-parcel.active {
      background-image: url(/static/media/parcel-tools-delete-parcel-hover.196369db.svg); }

.component--search-bar {
  z-index: 200;
  pointer-events: all;
  display: flex; }
  .component--search-bar .component--mapbox-geocoder {
    width: 475px;
    background-color: #5C67C0;
    padding: 10px 23px 10px 22px; }
    .component--search-bar .component--mapbox-geocoder .geocoder-input {
      font-size: 17px;
      position: relative;
      background-color: white;
      height: 30px;
      width: 430px;
      min-width: 240px;
      border: 1px solid #3A4276;
      border-radius: 15px; }
      .component--search-bar .component--mapbox-geocoder .geocoder-input input {
        font-size: 15px;
        width: 90%;
        height: 28px;
        padding: 5px 10px;
        border-radius: 15px;
        border: 0;
        margin: 0; }
        .component--search-bar .component--mapbox-geocoder .geocoder-input input:focus {
          color: rgba(0, 0, 0, 0.75);
          outline: 0;
          box-shadow: none;
          outline: thin dotted\8; }

.component--parcel-information-panel {
  background: white;
  width: 475px;
  max-height: calc(100% - 200px);
  -webkit-transition: opacity .2s linear, -webkit-transform .3s ease-out;
  transition: opacity .2s linear, -webkit-transform .3s ease-out;
  transition: transform .3s ease-out, opacity .2s linear;
  transition: transform .3s ease-out, opacity .2s linear, -webkit-transform .3s ease-out;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  opacity: 0;
  padding: 22px 22px 34px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .component--parcel-information-panel .actions-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px; }
    .component--parcel-information-panel .actions-container .action {
      font: 400 13px/18px Roboto Condensed;
      text-decoration: underline;
      cursor: pointer; }
  .component--parcel-information-panel.active {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1; }
  .component--parcel-information-panel label {
    margin: 0; }
  .component--parcel-information-panel button {
    margin-top: 34px;
    width: 177px;
    height: 32px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    padding: 7px 25px;
    border-radius: 16px; }
  .component--parcel-information-panel .component--property-listing-tab-container .content-wrapper {
    align-items: center; }
  .component--parcel-information-panel .component--property-listing-tab-container .component--parcel-information-table {
    padding-right: 1px;
    overflow-y: auto;
    overflow-x: hidden; }

.collaboration-container {
  align-self: center;
  margin: 15px;
  color: #939393;
  font-size: 14px; }
  .collaboration-container a {
    color: #3A4276;
    font-weight: 700;
    text-decoration: underline; }

.component--drawing-information {
  background-color: #FFFFFF;
  position: absolute;
  width: 236px;
  height: 180px;
  top: 20px;
  right: 120px;
  padding: 11px 17px;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  border-radius: 8px;
  box-shadow: 4px 4px 12px rgba(51, 51, 51, 0.3); }
  .component--drawing-information .header {
    font-weight: 300;
    padding-bottom: 7px;
    border-bottom: #CCCCCC 1px solid; }
  .component--drawing-information .section {
    margin-top: 15px;
    height: 42px; }
    .component--drawing-information .section .section-header {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 15px;
      font-weight: bold; }
    .component--drawing-information .section .section-body {
      margin-top: 4px;
      font-weight: 400; }

.component--new-project-map {
  display: flex;
  width: 100%;
  height: 100%; }
  .component--new-project-map button {
    position: absolute;
    width: 80px;
    height: 80px; }
  .component--new-project-map button {
    background-repeat: no-repeat;
    background-size: 80px;
    background-color: transparent;
    top: 70px;
    right: 20px;
    align-self: flex-end; }
  .component--new-project-map .streets-map-button {
    background-image: url(/static/media/map-view-streets.c745370f.png); }
    .component--new-project-map .streets-map-button:hover {
      background-image: url(/static/media/map-view-streets-hover.b57c858a.png); }
  .component--new-project-map .satellite-map-button {
    background-image: url(/static/media/map-view-satellite.cdcaf37c.png); }
    .component--new-project-map .satellite-map-button:hover {
      background-image: url(/static/media/map-view-satellite-hover.fcf5ecc6.png); }
  .component--new-project-map .component--branding-watermark {
    position: absolute;
    bottom: 13px;
    left: 47.5px; }
  .component--new-project-map .tool-tip {
    background-color: #333333;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 3px 10px; }
  .component--new-project-map.delete-parcel .mode-simple_select.mouse-pointer .mapboxgl-interactive {
    cursor: url(/static/media/cursor-delete-parcel.bbbd004e.svg) 6 15, auto; }
  .component--new-project-map.select-parcel .hover-parcel {
    cursor: url(/static/media/cursor-select-parcel.ff3e0008.svg), auto; }
  .component--new-project-map.combine-parcels .hover-parcel {
    cursor: url(/static/media/cursor-combine-parcels.7de5208e.svg), auto; }
  .component--new-project-map .mouse-pointer .mapboxgl-interactive {
    cursor: pointer; }
  .component--new-project-map .mode-direct_select.mouse-move .mapboxgl-interactive {
    cursor: move; }
  .component--new-project-map .mouse-add .mapboxgl-interactive {
    cursor: cell; }

.component--search-row {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  background-color: #f6f6f6;
  margin: 1px;
  padding: 0 15px 0 5px;
  flex-shrink: 0; }
  .component--search-row[data-tip] > div {
    pointer-events: none; }
  .component--search-row .toggle-field-wrapper {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 0; }
    .component--search-row .toggle-field-wrapper .toggle-container {
      padding: 0 8px 0 0; }
    .component--search-row .toggle-field-wrapper .toggle-box {
      margin: 0 10px 0 0; }
    .component--search-row .toggle-field-wrapper .toggle-label {
      margin: 0; }
  .component--search-row .slider-wrapper {
    width: 50%;
    height: 100%;
    padding: 8px 0 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .component--search-row .slider-wrapper .rc-slider-wrapper {
      width: 100%;
      display: flex;
      padding: 1px 5px 0;
      align-items: center;
      background-color: #E3E3E3; }
    .component--search-row .slider-wrapper .values {
      display: flex;
      justify-content: space-between; }

.component--smart-search-panel {
  position: absolute;
  top: 50px;
  background: white;
  width: 475px;
  max-height: calc(100% - 200px);
  padding: 10px 22px;
  pointer-events: all;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto; }
  .component--smart-search-panel .header {
    font-size: 16px;
    font-weight: 700; }
  .component--smart-search-panel .experimental {
    font-size: 12px;
    color: #666666; }
  .component--smart-search-panel .disclaimer {
    margin-bottom: 10px;
    font-size: 10px;
    line-height: 10px;
    color: #666666; }
  .component--smart-search-panel .collapse {
    background-image: url(/static/media/icon-collapse-black.ed6538b1.svg);
    background-repeat: no-repeat;
    display: block;
    margin-top: 10px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    flex-shrink: 0; }
  .component--smart-search-panel .expand {
    background-image: url(/static/media/icon-expand-black.33f50e2c.svg);
    background-repeat: no-repeat;
    display: block;
    height: 25px;
    width: 25px;
    cursor: pointer; }

.component--draw-parcel-information-panel {
  position: absolute;
  top: 50px;
  width: 475px;
  height: 585px;
  background-color: #FFFFFF;
  border-radius: 0px 0px 10px;
  padding: 0 15px 20px;
  pointer-events: all; }
  .component--draw-parcel-information-panel .header {
    font: 700 23px/40px Roboto Condensed;
    border-bottom: 1px solid #707070;
    text-align: center; }
  .component--draw-parcel-information-panel .block {
    display: flex;
    margin-top: 30px; }
    .component--draw-parcel-information-panel .block .number {
      font: 700 40px/71px Roboto;
      margin-right: 14px; }
    .component--draw-parcel-information-panel .block .image {
      height: 71px;
      width: 71px;
      margin-right: 28px;
      background-position: center; }
      .component--draw-parcel-information-panel .block .image.select-parcel {
        background-image: url(/static/media/parcel-tools-select-parcel.9303c559.svg);
        background-repeat: no-repeat;
        background-size: contain; }
      .component--draw-parcel-information-panel .block .image.combine-parcels {
        background-image: url(/static/media/parcel-tools-combine-parcels.5c72ddc0.svg);
        background-repeat: no-repeat;
        background-size: contain; }
      .component--draw-parcel-information-panel .block .image.draw-parcel {
        background-image: url(/static/media/parcel-tools-draw-parcel.78076254.svg);
        background-repeat: no-repeat;
        background-size: contain; }
      .component--draw-parcel-information-panel .block .image.edit-parcel {
        background-image: url(/static/media/parcel-tools-edit-parcel.983dd997.svg);
        background-repeat: no-repeat;
        background-size: contain; }
    .component--draw-parcel-information-panel .block .text {
      font: 300 16px/21px Roboto;
      letter-spacing: 0.16px;
      color: #333333; }
      .component--draw-parcel-information-panel .block .text .text-header {
        font: Bold 20px/23px Roboto;
        letter-spacing: 0.2px; }
  .component--draw-parcel-information-panel .button-container {
    display: flex;
    justify-content: center;
    margin-top: 58px; }
    .component--draw-parcel-information-panel .button-container .get-started {
      height: 42px;
      width: 170px;
      border: none;
      background-color: #F26C6C;
      color: #FFFFFF;
      font: 500 17px/20px Roboto;
      border-radius: 6px; }

.component--initial-values-panel {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 594px;
  padding: 37px;
  color: #333333; }
  .component--initial-values-panel p {
    font-size: 17px; }
  .component--initial-values-panel .input-container {
    display: flex;
    align-items: flex-end; }
  .component--initial-values-panel .default-far-note {
    color: #F65959;
    font-size: 12px;
    margin: 0 0 0 10px; }
  .component--initial-values-panel .note {
    font-size: 12px; }
  .component--initial-values-panel .section-header {
    font: Bold 23px/23px Roboto Condensed; }
  .component--initial-values-panel .bold {
    font-weight: bold; }
  .component--initial-values-panel .uses-container {
    display: flex;
    margin-top: 16px;
    margin-bottom: 52px; }
    .component--initial-values-panel .uses-container > :first-child {
      margin-right: 50px; }
    .component--initial-values-panel .uses-container .toggle-field-wrapper .toggle-container {
      padding-left: 6px; }
  .component--initial-values-panel .far-container {
    margin-bottom: 27px; }
    .component--initial-values-panel .far-container input {
      border: 2px solid #CCCCCC;
      border-radius: 8px;
      width: 116px;
      height: 37px;
      color: #999999;
      text-align: center;
      font-size: 15px;
      font-family: "Roboto", sans-serif; }
      .component--initial-values-panel .far-container input:focus, .component--initial-values-panel .far-container input:hover {
        outline: none;
        outline-offset: 0; }
  .component--initial-values-panel .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 39px; }
    .component--initial-values-panel .buttons-container button {
      width: 115px;
      font: Bold 13px/21px Roboto; }
      .component--initial-values-panel .buttons-container button:first-child {
        margin-right: 11px; }
      .component--initial-values-panel .buttons-container button.disabled {
        background: #CCCCCC;
        cursor: none; }
      .component--initial-values-panel .buttons-container button.continue {
        background: #F65959 0% 0% no-repeat padding-box; }
        .component--initial-values-panel .buttons-container button.continue:hover {
          background-color: #3A4276; }
      .component--initial-values-panel .buttons-container button.back {
        color: #F26C6C;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #F26C6C; }
        .component--initial-values-panel .buttons-container button.back:hover {
          background-color: #F65959;
          color: white; }

.component--welcome-slideshow {
  display: flex;
  flex-direction: column;
  width: 727px;
  height: 648px;
  background-color: #fff;
  border-radius: 10px; }
  .component--welcome-slideshow .header {
    margin: 0 25px;
    padding: 26px 50px 0;
    height: 85px;
    border-bottom: 0.5px solid #707070;
    font: bold 40px Roboto;
    letter-spacing: 0.8px;
    color: #5A5A5A;
    text-transform: uppercase;
    text-align: center; }
  .component--welcome-slideshow .content {
    display: flex;
    justify-content: space-between;
    height: 311px;
    margin-top: 20px;
    padding: 0 33px 0 11px; }
    .component--welcome-slideshow .content label {
      margin-top: 49px;
      color: #3A4276;
      font: bold 30px/30px Roboto;
      letter-spacing: 0.6px;
      text-transform: uppercase; }
    .component--welcome-slideshow .content p {
      margin-top: 23px;
      font: 100 20px/27px Roboto;
      letter-spacing: 0.4px;
      color: #999999; }
    .component--welcome-slideshow .content .image {
      flex-shrink: 0;
      margin-right: 52px;
      width: 345px;
      background-repeat: no-repeat;
      background-size: contain; }
      .component--welcome-slideshow .content .image.smart-search {
        background-image: url(/static/media/slideshow-smart-search.09d7eef7.png); }
      .component--welcome-slideshow .content .image.zoning-data {
        background-image: url(/static/media/slideshow-zoning-data.6e110ed9.png); }
      .component--welcome-slideshow .content .image.assumptions {
        background-image: url(/static/media/slideshow-assumptions.a35d8f8d.png); }
      .component--welcome-slideshow .content .image.back-of-envelope {
        background-image: url(/static/media/slideshow-back-of-envelope.d0224fb6.png); }
      .component--welcome-slideshow .content .image.custom-pdf {
        background-image: url(/static/media/slideshow-custom-pdf.2ad5b2a7.png); }
      .component--welcome-slideshow .content .image.parcel-and-uses {
        background-image: url(/static/media/slideshow-parcel-and-uses.32f54c7d.png); }
      .component--welcome-slideshow .content .image.draw-parcel {
        background-image: url(/static/media/slideshow-draw-parcel.33b3d83c.png); }
  .component--welcome-slideshow .dots-container {
    display: flex;
    margin-top: 36px;
    justify-content: center;
    align-items: center; }
    .component--welcome-slideshow .dots-container .dot {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      margin-right: 40px;
      cursor: pointer; }
      .component--welcome-slideshow .dots-container .dot::before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: #A3B5D3; }
      .component--welcome-slideshow .dots-container .dot:last-child {
        margin-right: 0; }
      .component--welcome-slideshow .dots-container .dot.active {
        border-radius: 11px;
        background-color: #3A4276; }
        .component--welcome-slideshow .dots-container .dot.active::before {
          background-color: #fff; }
  .component--welcome-slideshow .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 43px; }
    .component--welcome-slideshow .buttons-container button {
      width: 184px;
      height: 51px;
      background-color: #3A4276;
      border-radius: 5px;
      font: normal 25px/25px Roboto;
      letter-spacing: 0.5px;
      color: #FFFFFF; }
      .component--welcome-slideshow .buttons-container button:hover {
        background-color: #000; }
    .component--welcome-slideshow .buttons-container .skip {
      margin-top: 19px;
      text-decoration: underline;
      font: normal 20px/25px Roboto;
      letter-spacing: 0.4px;
      color: #999999;
      cursor: pointer; }

.component--new-project-page {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px; }
  .component--new-project-page .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: no-wrap; }
  .component--new-project-page .search-bar-wrapper {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    height: 100%; }
  .component--new-project-page .component--parcel-information-panel {
    pointer-events: all; }
  .component--new-project-page .map-pin {
    z-index: 0 !important;
    width: 30px;
    height: 46px;
    background-image: url(/static/media/map-pin.49a2142b.svg); }

.component--reset-password-page {
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: #F6F6F6;
  min-height: 100%; }
  .component--reset-password-page .hidden {
    display: none; }
  .component--reset-password-page a {
    font: inherit;
    font-weight: bold;
    color: #3A4276; }
  .component--reset-password-page button {
    background-color: #3A4276;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer; }
  .component--reset-password-page .underlined-link {
    font-weight: 500;
    color: #333333;
    text-decoration: underline;
    cursor: pointer; }
    .component--reset-password-page .underlined-link:hover {
      color: #3A4276;
      text-decoration: underline; }
  .component--reset-password-page .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .component--reset-password-page .content-wrapper {
    position: absolute;
    top: 241px; }
  .component--reset-password-page .header {
    font-size: 25px;
    font-family: "Roboto Condensed";
    font-weight: 700; }
  .component--reset-password-page .container {
    width: 395px;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    padding: 35px 30px 38px;
    box-shadow: 0px 8px 20px #00000017;
    border-radius: 10px; }
  .component--reset-password-page .content-header {
    flex-shrink: 0;
    width: 240.5px;
    height: 39.5px;
    background-image: url(/static/media/logo-inline.dd1ad572.svg);
    background-repeat: no-repeat;
    background-size: contain; }
  .component--reset-password-page .text-box {
    height: auto;
    width: auto;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    line-height: 19px; }
  .component--reset-password-page .form-container + .text-box {
    margin-top: 33px; }
  .component--reset-password-page .form-container .input-section {
    width: auto;
    margin-bottom: 16px; }
    .component--reset-password-page .form-container .input-section .input-label {
      width: auto;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin: 15px 0 5px 10px;
      line-height: 10px; }
    .component--reset-password-page .form-container .input-section .input-field {
      position: relative;
      height: 32px;
      width: 335px;
      background-color: #FDFDFD;
      padding: 4px 13px 4px 4px;
      border: 1px solid #CCCCCC;
      border-radius: 8px; }
      .component--reset-password-page .form-container .input-section .input-field input {
        border: none;
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 15px;
        font-family: inherit;
        font-size: 15px;
        background-color: transparent; }
        .component--reset-password-page .form-container .input-section .input-field input:-webkit-autofill {
          border: none;
          box-shadow: 0 0 0px 1000px white inset; }
        .component--reset-password-page .form-container .input-section .input-field input::-webkit-input-placeholder {
          color: #CCCCCC; }
        .component--reset-password-page .form-container .input-section .input-field input::-moz-placeholder {
          color: #CCCCCC; }
        .component--reset-password-page .form-container .input-section .input-field input:-ms-input-placeholder {
          color: #CCCCCC; }
        .component--reset-password-page .form-container .input-section .input-field input::-ms-input-placeholder {
          color: #CCCCCC; }
        .component--reset-password-page .form-container .input-section .input-field input::placeholder {
          color: #CCCCCC; }
      .component--reset-password-page .form-container .input-section .input-field.required:after {
        content: "*";
        position: absolute;
        top: 5px;
        right: 8px;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: bold;
        color: #F26C6C; }
      .component--reset-password-page .form-container .input-section .input-field.dropdown-arrow {
        background-image: url(/static/media/dropdown-triangle.d4ccc411.svg);
        background-repeat: no-repeat;
        background-position: center right;
        background-origin: content-box;
        background-size: 15px;
        content: "";
        display: block;
        padding-right: 7.5px;
        cursor: pointer; }
        .component--reset-password-page .form-container .input-section .input-field.dropdown-arrow input {
          cursor: pointer; }
        .component--reset-password-page .form-container .input-section .input-field.dropdown-arrow:hover {
          background-image: url(/static/media/dropdown-triangle-hover.4dec3ec1.svg); }
  .component--reset-password-page .form-container .submit {
    height: 35px;
    width: 97px;
    letter-spacing: 0.25px;
    padding: 0;
    pointer-events: all; }
    .component--reset-password-page .form-container .submit.disabled {
      cursor: inherit;
      color: white; }
  .component--reset-password-page .bold {
    font-weight: bold; }
  .component--reset-password-page .error-message {
    display: flex;
    align-items: flex-end;
    height: 31px;
    margin: 25px 0 13px 5px;
    width: 294px;
    text-align: left;
    vertical-align: middle;
    font-family: "Roboto Condensed", "Regular";
    font-size: 13px;
    color: #F26C6C;
    line-height: 16px; }
  .component--reset-password-page .reset-password {
    margin-top: 33px; }
  .component--reset-password-page .tos-agreement {
    height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 36px; }
    .component--reset-password-page .tos-agreement input[type="checkbox"] {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background-color: #F5F7F8;
      border: 1px solid #CCCCCC;
      padding: 6.5px;
      border-radius: 5px;
      width: 0;
      height: 0; }
      .component--reset-password-page .tos-agreement input[type="checkbox"]:checked {
        border: 1px solid #333333;
        background: url(/static/media/checkmark.e3d7ea53.svg);
        background-size: contain;
        background-repeat: no-repeat; }
      .component--reset-password-page .tos-agreement input[type="checkbox"]:focus {
        outline: none; }
    .component--reset-password-page .tos-agreement .text-box {
      margin-left: 14px; }
  @media screen and (max-width: 794px) {
    .component--reset-password-page .flex-wrapper {
      flex-direction: column-reverse; } }
  .component--reset-password-page .error-message {
    margin-top: 5px;
    margin-bottom: 8px; }
  .component--reset-password-page .submit {
    margin-top: 21px; }

.component--home-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF; }
  .component--home-page .top-bar {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 45px; }
    .component--home-page .top-bar .authentication-link {
      font: 400 14px/16px Roboto;
      color: #3A4276;
      letter-spacing: 0.4px;
      margin-right: 40px;
      cursor: pointer; }
  .component--home-page .footer {
    border-top: 1px solid #999999;
    width: 80%;
    padding: 64px 0 48px; }
    .component--home-page .footer .icon-watermark {
      width: 110px;
      height: 21px; }

.component--subscribe-button {
  font-size: 18px;
  font-weight: 500;
  height: 56px;
  width: 80%;
  border-radius: 28px;
  margin: auto 0;
  text-transform: uppercase;
  color: #333333;
  background-color: transparent;
  border: 1px solid #333333; }
  .component--subscribe-button:hover {
    color: #FFFFFF;
    background-color: #3A4276;
    border: 1px solid #3A4276; }

.component--subscription-rows .feature-row {
  height: 45px;
  display: flex; }
  .component--subscription-rows .feature-row:last-child .box.selected {
    border-radius: 0 0 8px 8px;
    border-bottom: 1px solid #F26C6C; }
  .component--subscription-rows .feature-row .box {
    background-color: #EDF1F7;
    display: flex;
    align-items: center;
    width: 285px; }
    .component--subscription-rows .feature-row .box.selected {
      background-color: #F26C6C15;
      border-left: 1px solid #F26C6C;
      border-right: 1px solid #F26C6C; }
  .component--subscription-rows .feature-row .label {
    width: 345px;
    padding-left: 35px;
    font-weight: 700; }
    .component--subscription-rows .feature-row .label a {
      color: #F75959; }
  .component--subscription-rows .feature-row.even .box {
    background-color: #FFFFFF; }
  .component--subscription-rows .feature-row .checked {
    height: 100%;
    width: 100%;
    background-position: center;
    background-image: url(/static/media/icon-checkmark.c20c2d60.svg);
    background-repeat: no-repeat; }

.component--subscription-required-content {
  padding: 50px 120px 55px 0px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media screen and (max-width: 1600px) {
    .component--subscription-required-content {
      padding-right: 20px; } }
  .component--subscription-required-content .content {
    width: 1200px; }
  .component--subscription-required-content .content-header {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .component--subscription-required-content .content-header .plan-period {
      padding-top: 68px;
      width: 345px; }
    .component--subscription-required-content .content-header .toggle-wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 40px;
      width: 196px;
      margin-left: 8px;
      border: solid 2px #F26C6C;
      border-radius: 20px; }
      .component--subscription-required-content .content-header .toggle-wrapper .toggle-button {
        width: 85px;
        height: 28px;
        border-radius: 14px;
        font: Bold 14px/28px Roboto Condensed;
        text-align: center;
        text-transform: uppercase;
        color: #777777;
        cursor: pointer; }
        .component--subscription-required-content .content-header .toggle-wrapper .toggle-button.selected {
          background-color: #F26C6C;
          color: #FFFFFF; }
    .component--subscription-required-content .content-header .discount-message {
      width: 196px;
      margin: 17px 0 0 8px;
      font: Bold 12px/14px Roboto;
      text-align: center; }
    .component--subscription-required-content .content-header .information {
      display: flex;
      flex-direction: column; }
    .component--subscription-required-content .content-header .plan-name-container {
      display: flex; }
    .component--subscription-required-content .content-header .plan-name-wrapper {
      width: 285px;
      height: 100%;
      padding: 8px 24px 0 24px;
      border-top: 1px solid transparent; }
      .component--subscription-required-content .content-header .plan-name-wrapper .plan-name {
        font: Bold 40px/58px Roboto;
        text-align: center;
        border-bottom: solid 1px #ACB0C6; }
      .component--subscription-required-content .content-header .plan-name-wrapper.selected-plan {
        border-radius: 8px 8px 0 0;
        background-color: #FFFFFF;
        border-left: 1px solid #F26C6C;
        border-top: 1px solid #F26C6C;
        border-right: 1px solid #F26C6C; }
    .component--subscription-required-content .content-header .price-container {
      display: flex; }
    .component--subscription-required-content .content-header .price-wrapper {
      width: 285px;
      height: 100%;
      padding: 15px 24px 10px 24px; }
      .component--subscription-required-content .content-header .price-wrapper .price {
        display: flex;
        justify-content: center;
        font: 400 48px/48px Roboto;
        letter-spacing: -4px; }
        .component--subscription-required-content .content-header .price-wrapper .price .cents {
          font: 400 30px/32px Roboto;
          vertical-align: top;
          letter-spacing: -2px; }
        .component--subscription-required-content .content-header .price-wrapper .price .suffix {
          height: 24px;
          width: 35px;
          margin: 3px 0 0 7px;
          border-radius: 4px;
          font: 700 11px/24px Roboto;
          letter-spacing: 0px;
          text-align: center;
          background-color: #59608D;
          color: #FFFFFF; }
      .component--subscription-required-content .content-header .price-wrapper.selected-plan {
        background-color: #FFFFFF;
        border-left: 1px solid #F26C6C;
        border-right: 1px solid #F26C6C; }
    .component--subscription-required-content .content-header .monthly-price-container {
      display: flex; }
    .component--subscription-required-content .content-header .monthly-price-wrapper {
      width: 285px;
      height: 100%;
      padding: 0 24px 15px; }
      .component--subscription-required-content .content-header .monthly-price-wrapper .monthly-price {
        padding: 7px;
        border-top: solid 1px #ACB0C6;
        border-bottom: solid 1px #ACB0C6;
        font: 700 14px/14px Roboto;
        font-style: italic;
        text-align: center;
        color: #F26C6C; }
      .component--subscription-required-content .content-header .monthly-price-wrapper.selected-plan {
        background-color: #FFFFFF;
        border-left: 1px solid #F26C6C;
        border-right: 1px solid #F26C6C; }
    .component--subscription-required-content .content-header .link-container {
      display: flex; }
    .component--subscription-required-content .content-header .link-wrapper {
      width: 285px;
      height: 100%;
      padding: 20px 24px 40px;
      text-align: center; }
      .component--subscription-required-content .content-header .link-wrapper a {
        display: inline-block;
        font: 400 17px/17px Roboto;
        color: #F26C6C; }
      .component--subscription-required-content .content-header .link-wrapper.selected-plan {
        background-color: #FFFFFF;
        border-left: 1px solid #F26C6C;
        border-right: 1px solid #F26C6C; }
    .component--subscription-required-content .content-header .buttons-container {
      display: flex;
      height: 80px; }
      .component--subscription-required-content .content-header .buttons-container .button-wrapper {
        display: flex;
        justify-content: center;
        width: 285px;
        height: 100%; }
      .component--subscription-required-content .content-header .buttons-container .selected-plan {
        background-color: #F26C6C;
        width: 100%;
        color: #FFFFFF;
        font: Bold 25px/80px Roboto;
        text-transform: uppercase;
        text-align: center; }

.component--subscribe {
  position: relative;
  padding: 13px 17px 0;
  overflow: scroll; }
  .component--subscribe .field {
    outline: none;
    height: 61px;
    background: #F1F5F7 0% 0% no-repeat padding-box;
    border: 1px solid #EEEEEE;
    margin-bottom: 21px;
    font: normal 13px/15px Roboto;
    padding: 0 18px;
    display: flex;
    align-items: center; }
    .component--subscribe .field::-webkit-input-placeholder {
      color: #BDBDBD; }
    .component--subscribe .field::-moz-placeholder {
      color: #BDBDBD; }
    .component--subscribe .field:-ms-input-placeholder {
      color: #BDBDBD; }
    .component--subscribe .field::-ms-input-placeholder {
      color: #BDBDBD; }
    .component--subscribe .field::placeholder {
      color: #BDBDBD; }
  .component--subscribe .input-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px; }
    .component--subscribe .input-row .field {
      flex: 1 1 50%;
      margin-bottom: 0; }
    .component--subscribe .input-row .error::-webkit-input-placeholder {
      color: #F26C6C; }
    .component--subscribe .input-row .error::-moz-placeholder {
      color: #F26C6C; }
    .component--subscribe .input-row .error:-ms-input-placeholder {
      color: #F26C6C; }
    .component--subscribe .input-row .error::-ms-input-placeholder {
      color: #F26C6C; }
    .component--subscribe .input-row .error::placeholder {
      color: #F26C6C; }
    .component--subscribe .input-row label {
      margin: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1 1 50%;
      color: #BDBDBD; }
    .component--subscribe .input-row .field:nth-child(2) {
      margin-left: 32px; }
    .component--subscribe .input-row discount-input {
      text-transform: uppercase; }
  .component--subscribe .divisor {
    border-top: 1px solid #EFEFEF;
    margin: 22px 0; }
  .component--subscribe .error-container {
    color: #F66C6C;
    font: 100 10px/11px Roboto;
    font-style: italic;
    text-align: center; }
  .component--subscribe .prices-container {
    position: relative; }
    .component--subscribe .prices-container .fetching-coupon-message {
      position: absolute;
      background-color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .component--subscribe .prices-container .price-row {
      display: flex;
      justify-content: flex-end;
      color: #1A1824;
      font: normal 13px/15px Roboto; }
      .component--subscribe .prices-container .price-row .price {
        width: 200px;
        text-align: right; }
      .component--subscribe .prices-container .price-row.discount {
        color: #F26C6C; }
      .component--subscribe .prices-container .price-row.final-price {
        color: #3A4276;
        font: bold 18px/24px Roboto; }
  .component--subscribe .initial-spinner {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 2; }
  .component--subscribe .submit {
    width: 450px;
    height: 61px;
    background: #3A4276 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px #00000040;
    border-radius: 3px;
    margin-bottom: 20px; }

.component--sign-up {
  position: relative;
  background-color: #FFFFFF;
  padding: 20px 12px;
  border-radius: 0px 0px 3px 3px;
  overflow-y: auto;
  max-height: calc(100vh - 171px); }
  .component--sign-up form {
    display: flex;
    flex-direction: column; }
    .component--sign-up form.hide {
      display: none; }
  .component--sign-up .input-field {
    margin-top: 16px; }
  .component--sign-up .error-container p {
    font: 300 10px/20px Roboto;
    font-style: italic;
    color: #F26C6C;
    margin-top: 7px;
    margin-bottom: -15px;
    white-space: pre-line; }
  .component--sign-up .tos-agreement {
    display: flex;
    align-items: center;
    margin-top: 20px; }
  .component--sign-up .text-box {
    font: 400 12px/14px Roboto;
    color: #777777; }
    .component--sign-up .text-box .underlined {
      text-decoration: underline;
      cursor: pointer; }
    .component--sign-up .text-box.right {
      margin-left: auto; }
  .component--sign-up a {
    font: 400 12px/14px Roboto;
    color: #777777;
    text-decoration: underline; }
  .component--sign-up input {
    width: 100%;
    background-color: #F1F5F7;
    border: 1px solid #EEEEEE;
    border-radius: 0; }
    .component--sign-up input.tos {
      width: 24px; }
  .component--sign-up .buttons-container {
    width: 100%; }
  .component--sign-up button {
    height: 60px;
    width: 100%;
    background-color: #3A4276;
    border-radius: 3px;
    margin-top: 31px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 10px;
    box-shadow: 0px 4px 10px #00000040; }
  .component--sign-up .submit:hover {
    background-color: #2C3258; }

.component-verify-email {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 28px 0; }
  .component-verify-email .icon {
    width: 220px;
    height: 189px;
    background-image: url(/static/media/icon-email-verification.87da3286.png);
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 60px; }
  .component-verify-email .content-wrapper {
    margin-top: 58px; }
  .component-verify-email .text-box {
    text-align: center;
    font: 400 13px/20px Roboto;
    color: #777777; }
    .component-verify-email .text-box .bold {
      font-weight: 700;
      color: #3A4276; }
    .component-verify-email .text-box .underlined-link {
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer; }
  .component-verify-email .footer {
    width: 80%;
    margin-top: 65px;
    display: flex;
    justify-content: space-between; }
    .component-verify-email .footer .text-box {
      font-size: 12px; }
  .component-verify-email button {
    height: 38px;
    width: 100%;
    background-color: #3A4276;
    border-radius: 3PX;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px;
    box-shadow: 0px 4px 10px #00000040; }

.component--welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 87px 0; }
  .component--welcome .welcome-image {
    width: 302px;
    height: 268px;
    background: url(/static/media/icon-welcome.eaa88ccc.svg) 0% 0% no-repeat padding-box; }
  .component--welcome p {
    font: 500 15px/20px Roboto;
    color: #3A4276;
    margin-top: 62px; }
  .component--welcome button {
    width: 190px;
    color: #fff;
    height: 38px;
    background: #3A4276 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 10px #00000040;
    border-radius: 3px;
    font: 400 16px/16px Roboto;
    margin-top: 45px; }

.component--sign-up-flow-popup {
  width: 483px;
  background-color: #FFFFFF;
  border-radius: 3px;
  max-height: 100vh;
  display: flex;
  flex-direction: column; }
  .component--sign-up-flow-popup .header-container {
    height: 66px;
    color: #3A4276;
    text-transform: uppercase;
    font: bold 20px/20px Roboto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #3A4276; }
  .component--sign-up-flow-popup .navigation {
    padding: 36px 65px 0;
    color: #BDBDBD; }
    .component--sign-up-flow-popup .navigation .icon-wrapper {
      display: flex;
      justify-content: space-between;
      padding-right: 3px; }
    .component--sign-up-flow-popup .navigation .label-wrapper {
      display: flex;
      justify-content: space-between; }
      .component--sign-up-flow-popup .navigation .label-wrapper p {
        font: normal 9px/9px Roboto; }
        .component--sign-up-flow-popup .navigation .label-wrapper p.active {
          color: #3A4276; }
    .component--sign-up-flow-popup .navigation .section {
      position: relative; }
      .component--sign-up-flow-popup .navigation .section .icon {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        background-position: center;
        border: 1px solid #D5D5E0;
        margin-bottom: 8px; }
        .component--sign-up-flow-popup .navigation .section .icon.email-verification {
          background-image: url(/static/media/icon-verify-email-inactive.222d91da.svg); }
        .component--sign-up-flow-popup .navigation .section .icon.checkout {
          background-image: url(/static/media/icon-subscribe-inactive.bdcc4604.svg); }
      .component--sign-up-flow-popup .navigation .section .line-divider {
        position: absolute;
        width: 127px;
        left: -127px;
        border-top: 1px #D5D5E0 solid;
        top: 16px; }
        .component--sign-up-flow-popup .navigation .section .line-divider.active {
          border-top: 1px #3A4276 solid; }
      .component--sign-up-flow-popup .navigation .section.active .icon {
        background-color: #3A4276;
        border: 1px solid #3A4276;
        box-shadow: 0px 4px 16px #3A427666; }
        .component--sign-up-flow-popup .navigation .section.active .icon.sign-up {
          background-image: url(/static/media/icon-sign-up-active.85a4f5dc.svg); }
        .component--sign-up-flow-popup .navigation .section.active .icon.email-verification {
          background-image: url(/static/media/icon-verify-email-active.02a34e13.svg); }
        .component--sign-up-flow-popup .navigation .section.active .icon.checkout {
          background-image: url(/static/media/icon-subscribe-active.73cda324.svg); }
  .component--sign-up-flow-popup .component--federated-authentication-providers .top-separator {
    width: 483px; }
  .component--sign-up-flow-popup .component--federated-authentication-providers .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 33px; }
  .component--sign-up-flow-popup .component--federated-authentication-providers .component--button-facebook {
    margin: 0; }
  .component--sign-up-flow-popup .component--federated-authentication-providers .component--button-google {
    margin: 0 0 0 15px; }
  .component--sign-up-flow-popup .component--activity-feedback {
    height: 499px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #777777; }

.component--subscription-required-page {
  display: grid;
  height: 100%;
  grid-template-rows: [header] 50px [content] auto;
  grid-template-columns: 300px auto;
  grid-template-areas: "header header" "side content"; }
  @media screen and (max-width: 1600px) {
    .component--subscription-required-page {
      grid-template-columns: 180px auto; } }
  .component--subscription-required-page .component--toolbar {
    grid-area: header; }
  .component--subscription-required-page .side {
    grid-area: side;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
    .component--subscription-required-page .side .select-plan-information {
      position: -webkit-sticky;
      position: sticky;
      top: 165px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 280px;
      padding: 8px 12px 20px 21px;
      background-color: #FFFFFF;
      border-radius: 6px;
      box-shadow: 0px 4px 10px #00000040; }
      .component--subscription-required-page .side .select-plan-information .separator {
        align-self: flex-start;
        width: 60px;
        height: 4px;
        border-radius: 2px;
        background-color: #F26C6C;
        margin-top: 16px; }
      .component--subscription-required-page .side .select-plan-information .header {
        width: 100%;
        font: 700 24px/26px Roboto;
        letter-spacing: 0.5px;
        color: #000000;
        margin-top: 16px;
        text-align: left; }
      .component--subscription-required-page .side .select-plan-information .text {
        font: 400 14px/18px Roboto;
        letter-spacing: 0.44px;
        color: #525252;
        margin-top: 16px;
        padding-right: 9px; }
      @media screen and (max-width: 1600px) {
        .component--subscription-required-page .side .select-plan-information {
          width: 160px;
          padding: 15px 8px 20px; }
          .component--subscription-required-page .side .select-plan-information .close-button {
            align-self: center; }
          .component--subscription-required-page .side .select-plan-information .separator {
            align-self: center;
            margin-top: 25px; }
          .component--subscription-required-page .side .select-plan-information .header {
            font: 700 16px/18px Roboto;
            text-align: center;
            letter-spacing: 0.33px; }
          .component--subscription-required-page .side .select-plan-information .text {
            font: 400 10px/14px Roboto;
            text-align: center;
            letter-spacing: 0.31px;
            padding: 0; } }
    .component--subscription-required-page .side .buttons-container {
      position: -webkit-sticky;
      position: sticky;
      bottom: 75px;
      display: flex;
      flex-direction: column; }
      .component--subscription-required-page .side .buttons-container button {
        width: 210px;
        height: 42px;
        border: none;
        border-radius: 3px;
        font: 500 16px/12px Roboto;
        color: #FFFFFF;
        box-shadow: 0px 4px 10px #00000040; }
        @media screen and (max-width: 1600px) {
          .component--subscription-required-page .side .buttons-container button {
            width: 150px; } }
        .component--subscription-required-page .side .buttons-container button.authentication {
          background-color: #3A4276;
          margin-bottom: 16px; }
          .component--subscription-required-page .side .buttons-container button.authentication:hover {
            background-color: #2C3258; }
        .component--subscription-required-page .side .buttons-container button.go-to-demo, .component--subscription-required-page .side .buttons-container button.sign-out {
          background-color: #F26C6C; }
          .component--subscription-required-page .side .buttons-container button.go-to-demo:hover, .component--subscription-required-page .side .buttons-container button.sign-out:hover {
            background-color: #D66060; }
  .component--subscription-required-page .component--subscription-required-content {
    grid-area: content; }

.component--account-links .icon-billing {
  background-image: url(/static/media/icon-sidebar-billing-inactive.da8bbbe3.svg); }

.component--account-links .icon-subscribe {
  background-image: url(/static/media/icon-sidebar-subscription-inactive.a3b51b88.svg); }

.component--account-links .side-bar-link:hover .icon-billing {
  background-image: url(/static/media/icon-sidebar-billing-active.5c03e1f4.svg); }

.component--account-links .side-bar-link:hover .icon-subscribe {
  background-image: url(/static/media/icon-sidebar-subscription-active.f003be60.svg); }

.component--dashboard-page .component--sidebar .component--support {
  margin-top: 94px;
  display: flex;
  flex-flow: column;
  min-height: calc(100vh - 250px); }
  .component--dashboard-page .component--sidebar .component--support .icon {
    height: 19px;
    background-size: contain; }
  .component--dashboard-page .component--sidebar .component--support .icon-cities {
    background-image: url(/static/media/icon-sidebar-cities-inactive.4f8c720d.svg); }
  .component--dashboard-page .component--sidebar .component--support .icon-webinar {
    background-image: url(/static/media/icon-sidebar-webinar-inactive.32c1151b.svg); }
  .component--dashboard-page .component--sidebar .component--support .icon-support {
    background-image: url(/static/media/icon-sidebar-support-inactive-dashboard.163766fa.svg); }
  .component--dashboard-page .component--sidebar .component--support .icon-blog {
    background-image: url(/static/media/icon-sidebar-blog-inactive.cab55333.svg); }
  .component--dashboard-page .component--sidebar .component--support .icon-contact-us {
    background-image: url(/static/media/icon-sidebar-contact-us-inactive.878af035.svg); }
  .component--dashboard-page .component--sidebar .component--support .side-bar-link:hover .icon-cities {
    background-image: url(/static/media/icon-sidebar-cities-active.26c2bafa.svg); }
  .component--dashboard-page .component--sidebar .component--support .side-bar-link:hover .icon-webinar {
    background-image: url(/static/media/icon-sidebar-webinar-active.6ef8a8cb.svg); }
  .component--dashboard-page .component--sidebar .component--support .side-bar-link:hover .icon-support {
    background-image: url(/static/media/icon-sidebar-support-active.823420b9.svg); }
  .component--dashboard-page .component--sidebar .component--support .side-bar-link:hover .icon-blog {
    background-image: url(/static/media/icon-sidebar-blog-active.aa573d87.svg); }
  .component--dashboard-page .component--sidebar .component--support .side-bar-link:hover .icon-contact-us {
    background-image: url(/static/media/icon-sidebar-contact-us-active.dd6fa174.svg); }

.component--dashboard-page .component--sidebar {
  padding: 29px 30px 15px 30px;
  background-color: #E5E7ED;
  position: relative;
  font-weight: 500;
  overflow: hidden; }
  .component--dashboard-page .component--sidebar .header {
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    height: 24px;
    border-bottom: solid 1px #999999; }
  .component--dashboard-page .component--sidebar .links-container {
    margin-top: 19px; }
  .component--dashboard-page .component--sidebar .side-bar-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #777777;
    margin-bottom: 10px; }
    .component--dashboard-page .component--sidebar .side-bar-link:hover {
      color: #F26C6C;
      text-decoration: none; }
  .component--dashboard-page .component--sidebar .icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 19px;
    margin-right: 13px; }

.component--application-links {
  display: flex;
  justify-content: space-around;
  align-items: baseline; }
  .component--application-links .application-link {
    width: 285px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .component--application-links .application-link.coming-soon p,
    .component--application-links .application-link.coming-soon h5 {
      color: #ACB0C6; }
  .component--application-links .bottom {
    font-size: 13px;
    font-weight: bold; }
  .component--application-links h5 {
    color: #333333;
    font-family: "Roboto Condensed";
    font-size: 17.5px;
    font-weight: bold;
    margin-top: 20px; }
  .component--application-links p {
    color: #777777;
    font-size: 12.5px;
    text-align: center;
    margin: 15px 0 0; }
  .component--application-links .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #3A4276;
    width: 186px;
    font-size: 13px;
    color: white;
    background-color: #3A4276;
    margin-top: 15px; }
    .component--application-links .btn:hover {
      background-color: #F26C6C;
      border-color: #F26C6C; }
  .component--application-links .site-analysis-icon {
    height: 130px;
    width: 158px;
    background-size: contain;
    background-image: url(/static/media/icon-dashboard-analyze-site.526b0b85.svg);
    background-repeat: no-repeat; }
  .component--application-links .existing-building-icon {
    height: 128px;
    width: 158px;
    background-size: contain;
    background-image: url(/static/media/icon-dashboard-existing-building.304e6756.svg);
    background-repeat: no-repeat; }
  .component--application-links .new-development-icon {
    height: 134px;
    width: 158px;
    background-size: contain;
    background-image: url(/static/media/icon-dashboard-new-development.add65f56.svg);
    background-repeat: no-repeat; }

.component--thumbnail {
  height: 153px;
  min-height: 153px;
  line-height: 153px;
  text-align: center;
  background-image: url(/static/media/default-thumbnail.06567b99.png);
  background-size: cover;
  color: rgba(102, 139, 152, 0.8);
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px #59618D;
  border-radius: 10px 10px 0 0;
  cursor: pointer; }
  .component--thumbnail .spinner {
    background-image: url(/static/media/busy-spinner-blue.3cbb1f2d.svg);
    background-repeat: no-repeat; }

.component--development-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px; }
  .component--development-card .component--return-on-cost {
    top: 6px;
    right: 6px;
    width: 80px;
    padding: 5px 5px 11px; }
    .component--development-card .component--return-on-cost .header {
      font: Bold 11px/13px Roboto;
      letter-spacing: 0.33px;
      text-transform: uppercase; }
    .component--development-card .component--return-on-cost .separator {
      margin-top: 2px;
      width: 20px;
      height: 2px;
      background-color: #F66C6C;
      border-radius: 1px; }
    .component--development-card .component--return-on-cost .value {
      margin-top: 8px;
      font: 700 20px/20px Roboto; }
    .component--development-card .component--return-on-cost .secondary {
      bottom: -13px;
      width: 80px;
      height: 21px;
      padding: 2px 0 0; }
      .component--development-card .component--return-on-cost .secondary .label {
        font: 300 10px/10px Roboto; }
      .component--development-card .component--return-on-cost .secondary .value {
        font: 700 10px/10px Roboto; }
  .component--development-card .shared {
    position: absolute;
    bottom: 104px;
    width: 100%;
    height: 25px;
    background-color: #3A4276;
    font: Bold 13px/25px Roboto;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase; }
  .component--development-card .card-content {
    position: relative;
    box-sizing: border-box;
    flex: 1 1;
    margin: 12px 12px 9px; }
  .component--development-card .development-name {
    font: 900 13px/21px Roboto;
    color: #3A4276;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden; }
    .component--development-card .development-name:hover {
      cursor: pointer; }
  .component--development-card .last-opened {
    margin-top: 5px;
    font: 300 10px/8px Roboto;
    color: #3A4276;
    text-align: center; }
  .component--development-card .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 16px; }

.component--pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
  line-height: 14px;
  color: #777777;
  font-weight: 500;
  font-size: 12px;
  white-space: pre;
  cursor: default; }
  .component--pagination .page-selector {
    display: flex; }
  .component--pagination .numbers {
    display: flex; }
  .component--pagination .current {
    color: #F26C6C; }
  .component--pagination .button {
    cursor: pointer; }
    .component--pagination .button:hover {
      color: #F26C6C; }

.component--developments-list {
  margin: 36px 0 65px; }
  .component--developments-list .developments-header {
    font-size: 13px;
    font-weight: bold;
    color: #333333;
    padding-bottom: 4px;
    border-bottom: solid 1px #999999; }
  .component--developments-list .developments-wrapper {
    min-height: 533px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 230px);
    grid-template-rows: repeat(auto-fit, 256px);
    justify-content: space-between;
    grid-gap: 22px 32px;
    padding: 19px 0;
    border-bottom: solid 1px #999999; }

.component--dashboard-content .component--footer {
  margin-top: auto;
  margin-bottom: 19px; }
  .component--dashboard-content .component--footer .component--branding-watermark {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    left: 0;
    width: 110.5px;
    height: 21px; }

.component--site-analysis-popup {
  width: 890px;
  height: 605px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px;
  color: #333333; }
  .component--site-analysis-popup .site-analysis-icon {
    width: 258px;
    height: 218px;
    background: transparent url(/static/media/icon-dashboard-analyze-site.526b0b85.svg) 0% 0% no-repeat padding-box;
    background-size: contain; }
  .component--site-analysis-popup p {
    font: normal 18px/25px Roboto;
    text-align: center;
    max-width: 514px; }
  .component--site-analysis-popup .header {
    font: Bold 24px/25px Roboto;
    margin-top: 33px;
    margin-bottom: 27px; }
  .component--site-analysis-popup .geocoder-container {
    margin-top: 45px;
    display: flex;
    align-items: center;
    width: 697px;
    border: 2px solid #3A4276;
    border-radius: 26px;
    height: 51px;
    padding-left: 27px; }
    .component--site-analysis-popup .geocoder-container.error {
      border: 2px solid #F26C6C; }
    .component--site-analysis-popup .geocoder-container .geocoder-icon {
      top: auto; }
    .component--site-analysis-popup .geocoder-container .component--mapbox-geocoder {
      flex: 1 1 100%; }
      .component--site-analysis-popup .geocoder-container .component--mapbox-geocoder input {
        font: normal 18px/22px Roboto;
        width: calc(100% - 30px);
        height: 21px; }
    .component--site-analysis-popup .geocoder-container button {
      font: 500 18px/22px Roboto;
      position: relative;
      right: -3px;
      letter-spacing: 0.36px;
      color: #FFFFFF;
      height: 51px;
      width: 242px;
      margin: 0;
      border-radius: 0px 26px 26px 0px;
      background: #3A4276 0% 0% no-repeat padding-box;
      border: 0px; }
      .component--site-analysis-popup .geocoder-container button:hover {
        background-color: #F26C6C; }
  .component--site-analysis-popup .error-container {
    color: #F26C6C;
    align-self: flex-start;
    margin-left: 90px; }
    .component--site-analysis-popup .error-container p {
      font-size: 14px;
      margin: 0; }

.component--new-development-popup {
  width: 890px;
  height: 605px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 43px; }
  .component--new-development-popup .new-development-icon {
    width: 258px;
    height: 218px;
    background: transparent url(/static/media/icon-dashboard-new-development.add65f56.svg) 0% 0% no-repeat padding-box;
    background-size: contain; }
  .component--new-development-popup p {
    font: normal 18px/25px Roboto;
    text-align: center;
    max-width: 514px; }
  .component--new-development-popup .header {
    font: Bold 24px/25px Roboto;
    margin-top: 33px;
    margin-bottom: 27px; }
  .component--new-development-popup .geocoder-container {
    margin-top: 45px;
    display: flex;
    align-items: center;
    width: 697px;
    border: 2px solid #3A4276;
    border-radius: 26px;
    height: 51px;
    padding-left: 27px; }
    .component--new-development-popup .geocoder-container.error {
      border: 2px solid #F26C6C; }
    .component--new-development-popup .geocoder-container .geocoder-icon {
      top: auto; }
    .component--new-development-popup .geocoder-container .component--mapbox-geocoder {
      flex: 1 1 100%; }
      .component--new-development-popup .geocoder-container .component--mapbox-geocoder input {
        font: normal 18px/22px Roboto;
        width: calc(100% - 30px); }
    .component--new-development-popup .geocoder-container button {
      font: 500 18px/22px Roboto;
      position: relative;
      right: -3px;
      letter-spacing: 0.36px;
      color: #FFFFFF;
      height: 51px;
      width: 242px;
      margin: 0;
      border-radius: 0px 26px 26px 0px;
      background: #3A4276 0% 0% no-repeat padding-box;
      border: 0px; }
      .component--new-development-popup .geocoder-container button:hover {
        background-color: #F26C6C; }
  .component--new-development-popup .error-container {
    color: #F26C6C;
    align-self: flex-start;
    margin-left: 90px; }
    .component--new-development-popup .error-container p {
      font-size: 14px;
      margin: 0; }

.component--dashboard-content {
  display: flex;
  flex-direction: column;
  padding: 40px 65px 0; }

.component--dashboard-page {
  display: grid;
  grid-template-columns: minmax(1147px, 1fr) 250px;
  grid-template-rows: 50px auto;
  grid-template-areas: "toolbar toolbar" "content sidebar";
  height: 100%;
  overflow: auto;
  background-color: #F6F6F6;
  color: #777777;
  font-weight: 400;
  font-size: 12.5px; }
  .component--dashboard-page .component--sidebar {
    grid-area: sidebar; }
  .component--dashboard-page .component--dashboard-toolbar {
    grid-area: toolbar; }
  .component--dashboard-page .component--dashboard-content {
    grid-area: content; }

